// global css
import './App.css';
import { createTheme, ThemeProvider, responsiveFontSizes } from '@mui/material/styles';

import { React } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { Button, Card, Divider } from '@mui/material';
import PageVisibility from 'react-page-visibility';
import FrontPage from './components/frontpage';
import TabSelect from './components/TabSelect';
import UserMenu from './components/UserMenu';
import LoginDialog from './components/LoginDialog';
import Logout from './components/Logout';
import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';

import { checkLoginStatusAndRouteAsNeeded } from './lib/stateTasks';
import RequestAccountConfirm from './components/RequestAccountConfirm';

// module css
import styles from './App.module.css';

let theme = createTheme({
    palette: {
        primary: {
            main: '#000000'
        },
        secondary: {
            main: '#ffffff'
        },
        third: {
            main: 'red',
            text: '#ff00ff'
        },
        text: {
            error: 'red',
            third: 'red',
            main: '#000000',
            second: '#fffffff'
        }
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 700,
            md: 900,
            lg: 1250,
            xl: 1536,
        },
    },
    typography: {
        subtitle1: {
            fontStyle: 'italic'
        }
    },
    'border-radius': 0
});

theme = responsiveFontSizes(theme);

const NotFound = (props) => {
    return (
        <div className={styles['not-found']}>
            <Card className={styles['not-found-card']}>
                <h1>Page not found</h1>
                <a href={props.linkPath} >{props.linkName}</a>
            </Card>
        </div>
    );
};

NotFound.propTypes = {
    linkPath: PropTypes.string.isRequired,
    linkName: PropTypes.string.isRequired,
};

function App() {
    const loggedIn = useSelector((state) => {
        return state.login.value;
    });

    return (
        <ThemeProvider theme={theme}>
            <PageVisibility onChange={(isVisible) => isVisible ? checkLoginStatusAndRouteAsNeeded() : null}>
                <div id="app-root" className={styles.app}>
                    <Router>
                        <div className={styles.header}>
                            <div className={styles['header-logo-container']}>
                                <a href='https://wara-ops.org/'>
                                    <img src={`${process.env.PUBLIC_URL}/WARA_OPS_Logo_White.png`} className={styles['header-logo-image']}/>
                                </a>
                            </div>
                            { loggedIn
                                ? <UserMenu />
                                : <div className={styles['login-header-button']}>
                                    <LoginDialog onActivate={bindClick => (
                                        <Button variant="outlined" color="secondary"
                                            onClick={bindClick} sx={{ border: '1px solid white' }}>
                                            Login
                                        </Button>
                                )} />
                                </div>
                            }
                        </div>
                        { loggedIn
                          ? <Routes>
                              <Route path='logout' element={ <Logout /> } />
                              <Route path="tabselect" element={ <TabSelect /> } />
                              <Route path="tabselect/:activeTab" element={ <TabSelect /> } />
                              <Route path="jwt" element={ <Navigate to={{ pathname: '/tabselect/JupyterLink', search: '?gentokendialog=true' }} replace /> } />
                              <Route path="/" element={ <Navigate to={{ pathname: '/tabselect' }} replace />} />
                              <Route path="*" element={ <NotFound linkPath="/tabselect" linkName="tabs" /> } />
                          </Routes>
                          : <Routes>
                              <Route path='logout' element={ <Logout /> } />
                              <Route path='home' element={ <FrontPage /> } />
                              <Route path="jwt" element={ <Navigate to={{ pathname: '/home', search: '?login=true&redirect=true' }} state={{ login: true, redirect: true, path: 'jwt' }} replace /> }/>
                              <Route path="tabselect" element={ <Navigate to={{ pathname: '/home', search: '?login=true&redirect=true' }} state={{ login: true, redirect: true, path: 'tabselect' }} replace /> }/>
                              <Route path="tabselect/:activeTab" element={ <Navigate to={{ pathname: '/home', search: '?login=true&redirect=true' }} state={{ login: true, redirect: true, path: 'tabselect/:' }} replace /> } />
                              <Route path="signup" element={ <Navigate to={{ pathname: '/home', search: '?signupdialog=true' }} replace /> } />
                              <Route path="confirmEmail" element={ <RequestAccountConfirm /> } />
                              <Route path='*' element={ <Navigate to='/home' state={{}} replace /> }/>
                          </Routes>}
                        <div className={styles['line-flex']}>
                            <div className={styles['before-footer-space']}>
                                <Divider></Divider>
                            </div>
                            <div className={styles.footer}>
                                <div className={styles['footer-left']}>
                                    <p data-type="group">© 2023 Ericsson AB</p>
                                </div>
                                <div className={styles['footer-right']}>
                                    <p>Contact by email <a href="mailto:support@erdc.ericsson.net">ER DC Support</a></p>
                                </div>
                            </div>
                        </div>
                    </Router>
                </div>
            </PageVisibility>
        </ThemeProvider>
    );
}

export default App;
