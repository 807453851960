import React, { useEffect, useState, useCallback } from 'react';
import Dialog from '@mui/material/Dialog';
import { Button, TextField, ButtonGroup } from '@mui/material';
import { login } from '../lib/apiRequests';
import { useDispatch } from 'react-redux';
import { loggedIn } from '../state/loginSlice';
import { GoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useLocation, useNavigate } from 'react-router-dom';

import LoadingButton from '@mui/lab/LoadingButton';

import PropTypes from 'prop-types';
import styles from './LoginDialog.module.css';

const loginImgPath = './placeholderLoginlogo.jpg';

function LoginDialog(props) {
    const [open, setOpen] = useState(false);
    const [errorLogin, setErrorLogin] = useState(null);
    const [loginImg, setLoginImg] = useState(null);
    const [spinner, setSpinner] = useState(false);
    const [next, setNext] = useState(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const handleClose = () => {
        if (open) {
            console.log('close dialog');
            setOpen(false);
            setValues({ ...values, password: '', username: '' });
        }
        postLoginHandleOngoing(false);
    };
    const handleClickOpen = () => {
        if (!open) {
            console.log('open dialog');
            setOpen(true);
        }
    };
    const [values, setValues] = useState({
        username: '',
        password: '',
        recaptchaToken: null,
    });
    const handleChange = (property) => (event) => {
        // console.log('onchange: ', event.target.value);
        setValues({ ...values, [property]: event.target.value });
    };

    // recaptcha
    // const [token, setToken] = useState();
    const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);

    function closeit() {
        handleClose();
    }

    const postLoginHandleOngoing = (onGoing) => {
        setSpinner(onGoing);
    };

    function loginHandle() {
        // Add this to make autologin work
        if (values.username === '') {
            setErrorLogin('username needs to be set');
            return;
        }
        if (values.password === '') {
            setErrorLogin('password needs to be set');
            return;
        }
        setErrorLogin(null);
        postLoginHandleOngoing(true);
        login(values).then(() => {
            dispatch(loggedIn(values.username));
            postLoginHandleOngoing(false);
            if (next) {
                if (location.state?.redirect) {
                    navigate(next);
                }
            } else {
                navigate('/tabselect');
            }
            handleClose();
        }).catch((err) => {
            if (err?.status) {
                if (err.status === 400) {
                    setErrorLogin('Are you a robot if not contact the support');
                } else if (err.status === 401) {
                    setErrorLogin('Bad password or username');
                } else if (err.status === 429) {
                    setErrorLogin('You\'ve made too many requests to the server, wait a while');
                } else if (err.status === 500) {
                    console.error('Login error: ', err);
                    setErrorLogin('Unknown error please, contact the support');
                } else {
                    console.error('Login error(2): ', err);
                    setErrorLogin('Unknown error please, contact the support');
                }
            } else {
                setErrorLogin('Couldn\'t reach the server');
            }
            setRefreshReCaptcha(!refreshReCaptcha);
            postLoginHandleOngoing(false);
        });
    }

    useEffect(() => {
        setErrorLogin(null);
    }, [open]);

    useEffect(() => {
        const photo = new Image();
        photo.src = loginImgPath;
        photo.onload = () => setLoginImg(photo.src);

        // save the place we came from if we need to redirect later
        const from = location.pathname || '/home';
        if (next === null && (from.startsWith('/tabselect') || from.startsWith('/jwt'))) {
            // We came from somewhere lets go there after login
            setNext(location);
            // console.log(navigate);
            // handleClickOpen();
        }
        if (location.state?.login && !open) {
            handleClickOpen();
        } else if (open) {
            handleClose();
        }
    }, [location]);

    // recaptcha
    const onVerify = useCallback((token) => {
        // setToken(token);
        setValues({ ...values, recaptchaToken: token });
        setTimeout(function () { setRefreshReCaptcha(r => !r); },
            100 * 1000); // timeout is 2 minutes
    }, []);

    return (
        <React.Fragment >
            {props.onActivate(handleClickOpen)}
            <Dialog open={!!loginImg && open}
                onClose={handleClose}
                onKeyDown={(e) => { if (e.key === 'Enter') loginHandle(); }}>
                <div className={styles['login-frame']}>
                    <div className={styles['login-image-container']}>
                        <img src={loginImg} alt="Logo" height="100px" />
                    </div>
                    <div className={styles['login-input-group']}>
                        <div className={styles['login-error-space']}>{errorLogin}</div>
                        <div className={styles['login-dialog-input']}>
                            <TextField disabled={spinner} fullWidth required error={ !(values?.username) } label="Username" id="user" type="username" value={values.username} margin='dense' onInput={handleChange('username')} />
                        </div>
                        <div className={styles['login-dialog-input']}>
                            <TextField disabled={spinner} fullWidth required error={ !(values?.password) } label="Password" id="pass" type="password" value={values.password} margin='dense' onInput={handleChange('password')} />
                        </div>
                        <GoogleReCaptcha
                            onVerify={onVerify}
                            refreshReCaptcha={refreshReCaptcha}
                        />
                    </div>
                    {next
                        ? <div className={styles['login-redirect-text']}>
                            {'Will redirect to ' + next.pathname }
                        </div>
                        : <></>
                    }
                    {/* { (!spinner && next) && }
                    { spinner && <CircularProgress /> } */}
                    <div className={styles['login-button-group']}>
                        <ButtonGroup>
                            <LoadingButton loading={ spinner } onClick={loginHandle}>Login</LoadingButton>
                            <Button disabled={ spinner } onClick={closeit}>Cancel</Button>
                        </ButtonGroup>
                    </div>
                </div>
            </Dialog>
        </React.Fragment>
    );
}

LoginDialog.propTypes = {
    onActivate: PropTypes.func.isRequired
};

export default LoginDialog;
