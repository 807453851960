import { React, useState } from 'react';
import { Button, Menu, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { logout } from '../lib/apiRequests';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import styles from './UserMenu.module.css';

function UserMenu() {
    const signum = useSelector((state) => state.login.user);
    // const dispatch = useDispatch();
    const [anchor, setAnchor] = useState(null);
    const navigate = useNavigate();

    const handleClick = (event) => {
        setAnchor(event.currentTarget);
    };

    const handleClose = () => {
        setAnchor(null);
    };

    const handleLogout = () => {
        logout().then(res => {
            if (res) {
                console.log('logout');
                navigate('/logout');
            }
            handleClose();
        }).catch(console.error);
    };

    const open = Boolean(anchor);

    return (<div className={styles['login-header-button']}>
        <Button variant="outlined" onClick={handleClick}
            sx={{ border: '1px solid white' }}
            aria-expanded={open ? 'true' : undefined} color="secondary">
            { signum }
            <MoreVertIcon sx={{ paddingLeft: '5px' }} />
        </Button>
        <Menu
            id="basic-menu"
            anchorEl={anchor}
            open={open}
            onClose={handleClose}
            MenuListProps={{
                'aria-labelledby': 'basic-button',
            }}
            >
            <div className={styles['button-link']}><MenuItem onClick={handleClose}><Link to="tabselect">Home</Link></MenuItem></div>
            <div className={styles['button-link']}><MenuItem onClick={handleClose}><Link to="jwt">Generate JWT</Link></MenuItem></div>
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
        </Menu>
    </div>);
}
export default UserMenu;
