import { isLoggedIn } from './apiRequests';
import { store } from '../store';

export async function checkLoginStatusAndRouteAsNeeded() {
    const LoggedIn = store.getState().login.value;
    if (LoggedIn) {
        try {
            await isLoggedIn();
        } catch {
            store.dispatch({ type: 'USER_LOGOUT' });
        }
    }
}
