import React, { useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Box, Typography } from '@mui/material';
// import FileDownloadIcon from '@mui/icons-material/FileDownload';
// import SearchIcon from '@mui/icons-material/Search';
// import TagIcon from '@mui/icons-material/Tag';
// import Meta from './logselectSubComponents/Meta';
import Files from './tabs/Files';
// import InsertChartIcon from '@mui/icons-material/InsertChart';
// import HomeIcon from '@mui/icons-material/Home';
import InfoIcon from '@mui/icons-material/Info';
// import MetricsViewer from './tabs/MetricsViewer';
// import Home from './tabs/Home';
import About from './tabs/About';
// import APIInfo from './tabs/APIInfo';
import ApiIcon from '@mui/icons-material/Api';
import DatasetCatalog from './tabs/DatasetCatalog';
import DatasetCatalogIcon from '@mui/icons-material/FormatListBulleted';
import Inspector from './tabs/Inspector';
import InspectorIcon from '@mui/icons-material/FindInPage';
import FilesIcon from '@mui/icons-material/Inventory';
import JupyterLink from './tabs/JupyterLink';
// import QueryMenu from './logselectSubComponents/QueryMenu';
// import { useSelector } from 'react-redux';
import DatasetSelect from './DatasetSelect';

import styles from './TabSelect.module.css';
import { Background } from '../lib/styled';
import { setValue } from '../state/tabSelectSlice';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';

function TabSelect() {
    // const DEFAULT_ACTIVE_TAB = 'DatasetCatalog';
    // const location = useLocation()
    const { activeTab } = useParams();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const value = useSelector(state => state.tabSelect.value);

    const dispatch = useDispatch();

    const tabIconSize = 'large';

    const tabList = [
        {
            label: (<Typography variant='body1' sx={{ fontSize: { xs: 10, sm: 16 } }}>Catalog</Typography>),
            icon: <DatasetCatalogIcon fontSize={tabIconSize} />,
            component: <DatasetCatalog />,
            path: 'DatasetCatalog'
        },
        {
            label: (<Typography variant='body1' sx={{ fontSize: { xs: 10, sm: 16 } }}>Overview</Typography>),
            icon: <InspectorIcon fontSize={tabIconSize} />,
            component: <Inspector />,
            path: 'Inspector'
        },
        {
            label: (<Typography variant='body1' sx={{ fontSize: { xs: 10, sm: 16 } }}>Files</Typography>),
            icon: <FilesIcon fontSize={tabIconSize} />,
            component: <Files />,
            path: 'Files'
        },
        {
            label: (<Typography variant='body1' sx={{ fontSize: { xs: 10, sm: 16 } }}>Jupyterhub</Typography>),
            icon: <ApiIcon fontSize={tabIconSize} />,
            component: <JupyterLink />,
            path: 'JupyterLink'
        },
        {
            label: (<Typography variant='body1' sx={{ fontSize: { xs: 10, sm: 16 } }}>About</Typography>),
            icon: <InfoIcon fontSize={tabIconSize} />,
            component: <About />,
            path: 'About'
        }
    ];

    const handleChange = (event, newValue, keepQuery) => {
        if (value === newValue) {
            return;
        }
        dispatch(setValue(newValue));
        console.log(`navigate /tabselect/${tabList[newValue].path}`);
        navigate({
            pathname: `/tabselect/${tabList[newValue].path}`,
            search: keepQuery ? searchParams.toString() : ''
        });
    };

    useLayoutEffect(() => {
        if (!(tabList.map(x => x.path).includes(activeTab))) {
            handleChange(null, 0);
            // dispatch(setValue(0));
            // navigate(`/tabselect/${DEFAULT_ACTIVE_TAB}`);
        } else {
            handleChange(null, tabList.findIndex(x => x.path === activeTab), true);
        }
    }, []);

    return (
        <div className={styles['tabs-header']}>
            <div className={styles['tabs-icon-menu']}>
                <Tabs
                    value={value}
                    to={value}
                    path={activeTab}
                    onChange={handleChange}
                    color="secondary"
                    aria-label="icon label tabs example"
                    centered>
                    {tabList.map((o, i) =>
                        <Tab
                            key={i}
                            icon={o.icon}
                            label={o.label}
                            path={o.path}
                            sx={{
                                padding: { xs: '8px', sm: '16px' },
                                minWidth: '50px',
                                width: { xs: '64px', sm: '100px', lg: '150px' }
                            }}
                        />)}
                </Tabs>
                <Box sx={{ borderBottom: 1, borderColor: 'divider', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <DatasetSelect />
                </Box>
            </div>
            <div className={styles['tabs-body']}>
                <Background>
                    {tabList[value].component}
                </Background>
            </div>
        </div>
    );
}

// const tabstyle = {
//     tab: {
//         minWidth: 200, // a number of your choice
//         width: 200, // a number of your choice
//     }
// };

export default TabSelect;
