import { React, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { styled } from '@mui/material/styles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Paper from '@mui/material/Paper';

// import stableSort from '../lib/stableSort';
import { setSelectedID } from '../state/datasetSlice';

import styles from './DatasetSelect.module.css';

import {
    useGetDatasetsQuery,
} from '../state/apiSlice';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.secondary.main,
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.main,
}));

export default function DatasetSelect() {
    const dispatch = useDispatch();
    const selectedID = useSelector(state => state.dataset.selectedID);
    const queryParameters = new URLSearchParams(window.location.search);
    const initialDsID = parseInt(queryParameters.get('datasetID')) || 0;

    const {
        data: datasets,
        isSuccess,
        isError,
        error
    } = useGetDatasetsQuery();

    const hasDatasets = isSuccess && datasets.filtered.length;

    useEffect(() => {
        if (initialDsID && hasDatasets && datasets.filtered.find(o => o.DatasetID === initialDsID)) {
            dispatch(setSelectedID(initialDsID));
            // dispatch(setSelectedID(datasets.data.filtered[0].DatasetID));
        } else if (selectedID === -1 && hasDatasets) {
            dispatch(setSelectedID(datasets.filtered[0].DatasetID));
        }
    }, [hasDatasets]);

    if (isError) {
        console.error('Failed to fetch datasets: ', error);
    }

    const handleSelect = (event) => {
        dispatch(setSelectedID(event.target.value));
    };

    const dsDisplayString = (ds) => {
        const role = ds.AccessRole === 'none' && ds.AccessType === 'open' ? 'open' : ds.AccessRole;
        return '[' + role + '] ' + ds.DatasetName;
    };

    return <Item className={styles['select-box']} sx={{ m: 1, width: '100%' }}>
        <FormControl size='small' fullWidth >
            <InputLabel id='select-dataset'>select a dataset</InputLabel>
            <Select
                labelId='select-dataset'
                id='demo-simple-select'
                value={hasDatasets ? (selectedID === -1 ? '' : selectedID) : 0}
                label='select a dataset'
                onChange={handleSelect}
                align='left'
            >
                {hasDatasets
                    ? [
                        <MenuItem key={'select-helper'} disabled value="">
                            <em>[your access] dataset name</em>
                        </MenuItem>,
                        ...datasets.filtered.map(ds =>
                            <MenuItem key={ds.DatasetID} value={ds.DatasetID} align='left'>
                                {dsDisplayString(ds)}
                            </MenuItem>
                        )
                    ]
                    : <MenuItem key='temItem' value={0} align='left'>no datasets found</MenuItem>}
            </Select>
        </FormControl>
    </Item>;
}
