import { React, useState, useLayoutEffect, useCallback, Fragment } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import LockIcon from '@mui/icons-material/Lock';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { styled, useTheme } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
// import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import AddCircleIcon from '@mui/icons-material/AddCircle';
// import InfoIcon from '@mui/icons-material/Info';
// import Tooltip from '@mui/material/Tooltip';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import LoadingButton from '@mui/lab/LoadingButton';

import { CircularProgress, ButtonGroup } from '@mui/material';

import Dialog from '@mui/material/Dialog';
// import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import styles from './Inspector.module.css';
import { stableSort } from '../../lib/dataTransforms';
import { delayedLockWrapCreator } from '../../lib/locks';
import {
    useGetDatasetsQuery,
    useGetRolesQuery,
    useGetAccessRequestsQuery,
    useAcceptAccessRequestMutation,
    useRejectAccessRequestMutation,
    useUpdateDatasetMutation,
    useAddRoleMutation,
    useRemoveRoleMutation
} from '../../state/apiSlice';

import { getLogo, printOrgName } from '../../lib/styled';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.secondary.main,
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.main
}));

const isAdmin = (ds) => ['owner', 'admin'].includes(ds.AccessRole);
const isOwner = (ds) => ds.AccessRole === 'owner';

const userToString = (u, withMail = true) => `${u.Name} (${u.User})` + (withMail ? `, ${u.Mail}` : '');
const capitalize = (s) => s ? s[0].toUpperCase() + s.slice(1) : '';

function comparator(a, b) {
    if (a < b) {
        return -1;
    } else if (a > b) {
        return 1;
    } else {
        return 0;
    }
}

function arraysEqual(a, b) {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;

    for (let i = 0; i < a.length; ++i) {
        if (a[i] !== b[i]) return false;
    }
    return true;
}

function AccessRoles(props) {
    const { id, isOwner, roles } = props;

    const [addRole] = useAddRoleMutation();
    const [removeRole] = useRemoveRoleMutation();

    const [openRemoveDialog, setOpenRemoveDialog] = useState(false);
    const [openAddDialog, setOpenAddDialog] = useState(false);

    const [updating, setUpdating] = useState(false);

    const [selectedUser, setSelectedUser] = useState({});

    const [addUserSignum, setAddUserSignum] = useState('');
    const [roleToAdd, setRoleToAdd] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const [dispatchLock, setDispatchLock] = useState(false);

    // The delay prevents us from clicking buttons in fading dialog windows
    const dispatchLockWrap = delayedLockWrapCreator(
        dispatchLock,
        setDispatchLock,
        useTheme().transitions.duration.leavingScreen
    );

    const handleOpenAddDialog = (role) => () => {
        setAddUserSignum('');
        setErrorMessage('');
        setRoleToAdd(role);
        setOpenAddDialog(true);
    };

    const handleCloseAddDialog = () => {
        dispatchLockWrap(() => setOpenAddDialog(false));
    };

    const handleConfirmAddDialog = async () => {
        if (addUserSignum) {
            setErrorMessage('');
            setUpdating(true);
            await dispatchLockWrap(async () => {
                try {
                    await addRole({ role: roleToAdd, datasetid: id, user: addUserSignum }).unwrap();
                    setOpenAddDialog(false);
                } catch (err) {
                    console.error(err);
                    setErrorMessage('failed to add user: ' + err.data);
                }
            });
            setUpdating(false);
        } else {
            setErrorMessage('please enter user signum');
        }
    };

    const handleOpenRemoveDialog = (user) => () => {
        setErrorMessage('');
        setSelectedUser(user);
        setOpenRemoveDialog(true);
    };

    const handleCloseRemoveDialog = () => {
        dispatchLockWrap(() => setOpenRemoveDialog(false));
    };

    const handleConfirmRemoveDialog = async () => {
        setUpdating(true);
        await dispatchLockWrap(async () => {
            try {
                await removeRole({ role: selectedUser.Role, datasetid: id, user: selectedUser.User }).unwrap();
                setOpenRemoveDialog(false);
            } catch (err) {
                console.error(err);
                setErrorMessage('failed to remove user: ' + err.data);
            }
        });
        setUpdating(false);
    };

    const customChip = (user, onDeleteFunc) => <Chip
        key={user.User}
        variant="outlined"
        onDelete={onDeleteFunc}
        label={<Typography variant='body' style={{ whiteSpace: 'normal', wordBreak: 'break-word' }}>{userToString(user)}</Typography>}
        sx={{ ml: 1, mt: 0.25, mb: 0.25 }}
        style={{ height: '100%' }}
    />;

    const roleInfo = (role) => {
        if (role === 'user') {
            return 'Users can access the data within the dataset';
        } else if (role === 'admin') {
            return 'Admins have full access, except for adding/removing other admins.';
        } else {
            return '';
        }
    };

    const content = <>
        <Typography margin='dense' variant='h6'>
            Access roles
        </Typography>
        <Grid container direction="column">
            <Grid item>
                <Typography variant='h6' sx={{ ml: { xs: 0, md: 2, lg: 4 }, mt: 1 }}>
                    <b>Owner:</b>
                    {customChip(roles.owner, null)}
                </Typography>
            </Grid>
            <Grid item>
                <Typography variant='h6' sx={{ ml: { xs: 0, md: 2, lg: 4 }, mt: 1 }}>
                    <b>Admins:</b>
                    {roles.admins.map(admin =>
                        customChip(admin, isOwner ? handleOpenRemoveDialog({ ...admin, Role: 'admin' }) : null)
                    )}
                    {isOwner
                        ? <IconButton aria-label="add" onClick={handleOpenAddDialog('admin')}>
                            <AddCircleIcon />
                        </IconButton>
                        : <></>}
                </Typography>
            </Grid>
            <Grid item>
                <Typography variant='h6' sx={{ ml: { xs: 0, md: 2, lg: 4 }, mt: 1 }}>
                    <b>Users:</b>
                    {roles.users.map(user =>
                        customChip(user, handleOpenRemoveDialog({ ...user, Role: 'user' }))
                    )}
                    <IconButton aria-label="add" onClick={handleOpenAddDialog('user')}>
                        <AddCircleIcon />
                    </IconButton>
                </Typography>
            </Grid>
        </Grid>
        <Dialog open={openRemoveDialog} onClose={handleCloseRemoveDialog}>
            <DialogTitle>
                Remove {userToString(selectedUser, false)} from the {capitalize(selectedUser.Role)} access role?
            </DialogTitle>
            <DialogContent>
                {errorMessage
                ? <Typography color='text.error'>
                    {errorMessage}
                </Typography>
                : <></>}
            </DialogContent>
            <div style={{ alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
                <ButtonGroup sx={{ mb: 2 }} >
                    <LoadingButton loading={updating} onClick={handleConfirmRemoveDialog}>
                        <span>Remove</span>
                    </LoadingButton>
                    <Button disabled={updating} onClick={handleCloseRemoveDialog}>Cancel</Button>
                </ButtonGroup>
            </div>
        </Dialog>
        <Dialog open={openAddDialog} onClose={handleCloseAddDialog}>
            <DialogTitle>
                Add to access role {capitalize(roleToAdd)}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {roleInfo(roleToAdd)}
                </DialogContentText>
                <TextField
                    id='dataset-name'
                    label='signum'
                    fullWidth
                    margin='dense'
                    error={!!errorMessage}
                    helperText={errorMessage || ' '}
                    value={addUserSignum}
                    disabled={updating}
                    onChange={(event) => setAddUserSignum(event.target.value)}
                />
            </DialogContent>
            <div style={{ alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
                <ButtonGroup sx={{ mb: 2 }} >
                    <LoadingButton loading={updating} onClick={handleConfirmAddDialog}>
                        <span>Add</span>
                    </LoadingButton>
                    <Button disabled={updating} onClick={handleCloseAddDialog}>Cancel</Button>
                </ButtonGroup>
            </div>
        </Dialog>
    </>;
    return content;
}

function AccessRequests({ id, accReqs }) {
    const [dID] = useState(id);
    const [accessRequests] = useState(accReqs);
    const queryParameters = new URLSearchParams(window.location.search);
    const accessRequestUser = queryParameters.get('accessRequestUser');

    const [acceptAccReq] = useAcceptAccessRequestMutation();
    const [rejectAccReq] = useRejectAccessRequestMutation();

    const [openAccReqDialog, setOpenAccReqDialog] = useState(false);
    const [selectedAccReq, setSelectedAccReq] = useState({});
    const [accReqDispatchLock, setAccReqDispatchLock] = useState(false);
    const [accReqDispatchError, setAccReqDispatchError] = useState('');

    const [updatingAccept, setUpdatingAccept] = useState(false);
    const [updatingReject, setUpdatingReject] = useState(false);

    // The delay prevents us from clicking buttons in fading dialog windows
    const accReqDispatchLockWrap = delayedLockWrapCreator(
        accReqDispatchLock,
        setAccReqDispatchLock,
        useTheme().transitions.duration.leavingScreen
    );

    const handleAccReqOpen = (accReq) => () => {
        setSelectedAccReq(accReq);
        setAccReqDispatchError('');
        setOpenAccReqDialog(true);
    };

    const openAccReq = useCallback((accReq) => {
        const index = accessRequests.findIndex(x => x.User === accessRequestUser);
        if (index >= 0) {
            // handleAccReqOpen(accessRequests[index]);
            setSelectedAccReq(accessRequests[index]);
            setAccReqDispatchError('');
            setOpenAccReqDialog(true);
        }
    }, [accessRequestUser, accessRequests]);

    const handleAccReqAccept = async () => {
        setUpdatingAccept(true);
        await accReqDispatchLockWrap(async () => {
            try {
                await acceptAccReq({ datasetid: dID, user: selectedAccReq.User }).unwrap();
                setOpenAccReqDialog(false);
            } catch (err) {
                console.error(err);
                setAccReqDispatchError('Failed to accept access request: ' + err.data);
            }
        });
        setUpdatingAccept(false);
    };

    const handleAccReqReject = async () => {
        setUpdatingReject(true);
        await accReqDispatchLockWrap(async () => {
            try {
                await rejectAccReq({ datasetid: dID, user: selectedAccReq.User }).unwrap();
                setOpenAccReqDialog(false);
            } catch (err) {
                console.error(err);
                setAccReqDispatchError('Failed to reject access request: ' + err.data);
            }
        });
        setUpdatingReject(false);
    };

    const handleAccReqClose = () => {
        accReqDispatchLockWrap(() => setOpenAccReqDialog(false));
    };

    useLayoutEffect(() => {
        if (accessRequestUser) {
            openAccReq(accessRequestUser);
        }
    }, [accessRequestUser, accessRequests]);

    const content = <>
        <Typography margin='dense' variant='h6'>
            Access requests
        </Typography>
        <Stack spacing={0.5} justifyContent="flex-start">
            {accReqs.map(accReq =>
                <Chip
                    key={accReq.User}
                    icon={<HelpOutlineIcon />}
                    variant="outlined"
                    onClick={handleAccReqOpen(accReq)}
                    style={{ height: '100%' }}
                    label={
                        <Typography
                            variant='body'
                            style={{
                                whiteSpace: 'normal',
                                wordBreak: 'break-word'
                            }}>
                            {userToString(accReq)}
                        </Typography>} />)}
        </Stack>
        <Dialog open={openAccReqDialog} onClose={handleAccReqClose}>
            <DialogTitle>Access request for {selectedAccReq.Name}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Create date: {selectedAccReq.CreateDate ? selectedAccReq.CreateDate.toLocaleDateString() : ''}
                </DialogContentText>
                <DialogContentText>
                    Email: {selectedAccReq.Mail}
                </DialogContentText>
                <DialogContentText>
                    Note: {selectedAccReq.Note}
                </DialogContentText>
                {accReqDispatchError
                    ? <Typography color='text.error'>
                        {accReqDispatchError}
                    </Typography>
                    : <></>}
            </DialogContent>
            <div style={{ alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
                <ButtonGroup sx={{ mb: 2 }}>
                    <LoadingButton loading={updatingAccept} disabled={updatingReject} onClick={handleAccReqAccept} color='success'>Accept</LoadingButton>
                    <LoadingButton loading={updatingReject} disabled={updatingAccept} onClick={handleAccReqReject} color='error'>Reject</LoadingButton>
                    <Button disabled={updatingAccept || updatingReject} onClick={handleAccReqClose}>Cancel</Button>
                </ButtonGroup>
            </div>
        </Dialog>
    </>;

    return content;
}

function Metadata(props) {
    const { ds } = props;

    const [updateDataset, { isLoading: dsIsLoading }] = useUpdateDatasetMutation();

    const [edit, setEdit] = useState(false);
    const [saveError, setSaveError] = useState('');
    const [updating, setUpdating] = useState(false);

    const [datasetName, setDatasetName] = useState('');
    const [shortInfo, setShortInfo] = useState('');
    const [longInfo, setLongInfo] = useState('');
    const [accessType, setAccessType] = useState('');

    // Tags are moved into strings in order to allow manipulation in text fields.
    // When an edit is accepted, this string needs to be cast into an array again with proper error checking.
    const [tags, setTags] = useState('');

    const commaListParse = (s) => {
        if (!s) {
            return [];
        }

        const l = s.split(',').map(s => s.trim());

        if (!l.every(e => e)) {
            throw new Error('no empty items between commas');
        }

        if (new Set(l.map(s => s.toLowerCase())).size !== l.length) {
            throw new Error('No duplicate items');
        }

        return l;
    };

    const handleEditCancel = () => {
        setEdit(false);
        setSaveError('');
    };

    const handleEditToggle = async (event) => {
        if (!dsIsLoading) {
            if (edit) { // if we try to save (edit is true but not cancel), we update if changes has been made
                try {
                    const updateObj = {};
                    if (datasetName !== ds.DatasetName) {
                        updateObj.name = datasetName;
                    }
                    if (shortInfo !== ds.ShortInfo) {
                        updateObj.short_info = shortInfo;
                    }
                    if (longInfo !== ds.LongInfo) {
                        updateObj.long_info = longInfo;
                    }
                    if (accessType !== ds.AccessType) {
                        updateObj.access_type = accessType;
                    }

                    // split on comma, trim any remaining commans and beginning/trailing whitespaces, filter out empty
                    const tagArray = stableSort(commaListParse(tags), comparator);
                    if (!arraysEqual(tagArray, stableSort(ds.Tags, comparator))) {
                        updateObj.tags = tagArray;
                    }

                    if (Object.keys(updateObj).length) {
                        setUpdating(true);
                        await updateDataset({ datasetid: ds.DatasetID, metadata: updateObj }).unwrap();
                        setUpdating(false);
                    }

                    setSaveError('');
                    setEdit(false); // we do not toggle if error is thrown
                } catch (err) {
                    let errstr;
                    if (typeof err === 'object' && err.data) {
                        errstr = err.data.split(':').at(-1);
                    } else if (typeof err === 'object' && err.message) {
                        errstr = err.message;
                    } else {
                        errstr = err;
                    }
                    console.error(err);
                    setSaveError('Failed to update dataset: ' + errstr);
                    setUpdating(false);
                }
            } else { // if edit is false, set it to true and load edit states
                setDatasetName(ds.DatasetName);
                setShortInfo(ds.ShortInfo);
                setLongInfo(ds.LongInfo);
                setTags(ds.Tags.join(', '));
                setEdit(true);
                setAccessType(ds.AccessType);
            }
        }
    };

    let content;
    if (edit) {
        content = <>
            <Box style={{ textAlign: 'left' }} >
                <Grid container direction="row" justifyContent="flex-start" alignItems="center" wrap="nowrap" >
                    <Grid item>
                        <FormControl sx={{ m: 1, ml: 0, minWidth: 120 }} size="small">
                            <InputLabel id="select-access-type-label">Access Type</InputLabel>
                            <Select
                                labelId="select-access-type-label"
                                id="select-access-type"
                                value={accessType}
                                label="Access Type"
                                onChange={(event) => setAccessType(event.target.value)}
                                disabled={updating}
                            >
                                <MenuItem value={'closed'}>Restricted access</MenuItem>
                                <MenuItem value={'open'}>Open access</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item>
                        {accessType === 'open'
                        ? <Typography color='text.error'>
                            Warning: with open access anyone can view the data
                        </Typography>
                        : <></> }
                    </Grid>
                </Grid>
            </Box>
            <TextField
                id='dataset-name'
                label='Dataset Name'
                fullWidth
                margin='dense'
                value={datasetName}
                onChange={(event) => setDatasetName(event.target.value)}
                disabled={updating}
            />
            <TextField
                id='short-description'
                label='Short description'
                fullWidth
                margin='dense'
                multiline
                rows={2}
                value={shortInfo}
                onChange={(event) => setShortInfo(event.target.value)}
                disabled={updating}
            />
            <TextField
                id='long-description'
                label='Long description'
                fullWidth
                margin='dense'
                multiline
                rows={4}
                value={longInfo}
                onChange={(event) => setLongInfo(event.target.value)}
                disabled={updating}
            />
            <TextField
                id='tags'
                label='Tags (separate each tag with a comma)'
                fullWidth
                margin='dense'
                multiline
                rows={2}
                value={tags}
                onChange={(event) => setTags(event.target.value)}
                disabled={updating}
            />
        </>;
    } else {
        content = <>
            <Item>
                <Grid container justifyContent='space-between' direction={{ xs: 'column-reverse', md: 'row' }}>
                    <Grid container item direction='column' justifyContent="flex-start" spacing={1} xs={6}>
                        <Grid item>
                            <Typography margin='dense' component='h2' variant='h5' align='left'>{ds.DatasetName}</Typography>
                        </Grid>
                        <Grid item>
                            <Typography margin='dense' variant='subtitle1' align='left'>{ds.ShortInfo}</Typography>
                        </Grid>
                        <Grid item>
                            <Box>
                                {ds.AccessType === 'open'
                                    ? <Chip icon={<Diversity3Icon />} variant="outlined" label="Open access" />
                                    : <Chip icon={<LockIcon />} variant="outlined" label="Restricted access" />}
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid item align='center'>
                        <img src={getLogo(ds.Organization)} className={styles['logo-container']}/>
                    </Grid>
                </Grid>
            </Item>
            <Item sx={{ mt: 1 }}>
                <Typography style={{ whiteSpace: 'pre-line' }} margin='dense' variant='body' paragraph>
                    <b>Description:</b> {ds.LongInfo}
                </Typography>
                <Typography margin='dense' variant='body' paragraph>
                    <b>Organization:</b> {printOrgName(ds.Organization)}
                </Typography>
                <Typography margin='dense' variant='body' paragraph>
                    <b>Dataset ID:</b> {ds.DatasetID}
                </Typography>
                <Typography margin='dense' variant='body' paragraph>
                    <b>Creation date:</b> {ds.CreateDate.toLocaleDateString()}
                </Typography>
                <Typography margin='dense' variant='body' paragraph>
                    <b>Type of data:</b> {ds.Category}
                </Typography>
            </Item>
            <Item sx={{ mt: 1, mb: 1 }}>
                <Typography margin='dense' variant='body'>
                    <b>Tags:</b> {ds.Tags.map(tag => <Chip key={tag} size="small" variant="outlined" label={tag} sx={{ mr: 0.5, mt: 0.25, mb: 0.25 }}/>)}
                </Typography>
            </Item>
        </>;
    }

    let editButtons;
    if (edit) {
        editButtons = <ButtonGroup>
            <LoadingButton
                key='accept-button'
                aria-label='accept-change'
                onClick={handleEditToggle}
                startIcon={<CheckIcon />}
                loadingPosition='start'
                loading={updating}
                >
                Accept
            </LoadingButton>
            <Button
                key='cancel-button'
                aria-label='cancel-change'
                onClick={handleEditCancel}
                startIcon={<ClearIcon />}
                disabled={updating}
                >
                Cancel
            </Button>
        </ButtonGroup>;
    } else {
        editButtons = <Button
            key='edit-button'
            variant='outlined'
            aria-label='edit'
            onClick={handleEditToggle}
            startIcon={<EditIcon />}
            >
            Edit
        </Button>;
    }

    return <>
        {content}
        {isAdmin(ds)
            ? editButtons
            : <></>}
        {saveError
            ? <Typography color='text.error'>
                {saveError}
            </Typography>
            : <></>}
    </>;
}

function FetchAndDisplayData(props) {
    const { ds } = props;

    const content = <Metadata
        key={'displaydata-' + ds.DatasetID}
        ds={ds}
        // handleReSortDatasets={handleReSortDatasets}
    />;

    let adminContent = <></>;
    if (isAdmin(ds)) {
        adminContent = <div>
            <CircularProgress sx={{ m: 2 }} />
            <Typography align='center'>
                Fetching admin content...
            </Typography>
        </div>;

        const { data: fetchedRoles, isError: rIsError, error: rError } = useGetRolesQuery(ds.DatasetID);
        const { data: fetchedAccessRequests, isError: aIsError, error: aError } = useGetAccessRequestsQuery(ds.DatasetID);

        if (fetchedRoles && fetchedAccessRequests) {
            const reduceObj = (o, keys) => {
                const newObj = {};
                for (const key of keys) {
                    newObj[key] = o[key];
                }
                return newObj;
            };

            const userKeys = Object.keys(userProp);
            const roles = {
                owner: reduceObj(fetchedRoles.Roles.find(o => o.AccessRole === 'owner'), userKeys),
                admins: fetchedRoles.Roles.filter(o => o.AccessRole === 'admin').map(o => reduceObj(o, userKeys)),
                users: fetchedRoles.Roles.filter(o => o.AccessRole === 'user').map(o => reduceObj(o, userKeys))
            };

            const accReqKeys = Object.keys(accReqProp);
            const accReqs = fetchedAccessRequests.map(o => reduceObj(o, accReqKeys));

            adminContent = <Grid container>
                <Grid item xs={12} lg={8}>
                    <Item sx={{ mt: 1, mb: 1 }}>
                        <AccessRoles id={ds.DatasetID} isOwner={isOwner(ds)} roles={roles} />
                    </Item>
                </Grid>
                <Grid item xs={12} lg={4}>
                    <Item sx={{ mt: 1, mb: 1, ml: { xs: 0, lg: 1 } }}>
                        <AccessRequests id={ds.DatasetID} accReqs={accReqs} />
                    </Item>
                </Grid>
            </Grid>;
        } else if (rIsError) {
            console.error('Failed to fetch roles: ', rError);
        } else if (aIsError) {
            console.error('Failed to fetch access requests ', aError);
        }
    }

    return <>
        {content}
        {adminContent}
    </>;
}

const datasetProp = {
    DatasetID: PropTypes.number.isRequired,
    DatasetName: PropTypes.string.isRequired,
    Organization: PropTypes.string.isRequired,
    Category: PropTypes.string.isRequired,
    AccessRole: PropTypes.string.isRequired,
    CreateDate: PropTypes.instanceOf(Date).isRequired,
    ShortInfo: PropTypes.string.isRequired,
    LongInfo: PropTypes.string.isRequired,
    AccessType: PropTypes.string.isRequired,
    Tags: PropTypes.arrayOf(PropTypes.string).isRequired
};

const userProp = {
    User: PropTypes.string.isRequired,
    Name: PropTypes.string.isRequired,
    Mail: PropTypes.string.isRequired
};

const accReqProp = {
    User: PropTypes.string.isRequired,
    Name: PropTypes.string.isRequired,
    Mail: PropTypes.string.isRequired,
    CreateDate: PropTypes.instanceOf(Date).isRequired,
    Note: PropTypes.string.isRequired,
};

Metadata.propTypes = {
    ds: PropTypes.shape(datasetProp)
};

AccessRoles.propTypes = {
    id: PropTypes.number.isRequired,
    isOwner: PropTypes.bool.isRequired,
    roles: PropTypes.shape({
        owner: PropTypes.shape(userProp).isRequired,
        admins: PropTypes.arrayOf(PropTypes.shape(userProp)).isRequired,
        users: PropTypes.arrayOf(PropTypes.shape(userProp)).isRequired
    }),
};

AccessRequests.propTypes = {
    id: PropTypes.number.isRequired,
    accReqs: PropTypes.arrayOf(PropTypes.shape(accReqProp))
};

FetchAndDisplayData.propTypes = {
    ds: PropTypes.shape(datasetProp)
};

export default function Inspector() {
    const {
        data: datasets,
        isSuccess,
        isError,
        error
    } = useGetDatasetsQuery();

    // const dispatch = useDispatch();

    const selectedID = useSelector(state => state.dataset.selectedID);

    // const handleReSortDatasets = (newname) => {
    //     const newdatasets = structuredClone(datasets.filtered);
    //     newdatasets[idx].DatasetName = newname;
    //     // dispatch(setSelectedID(sortDatasets(newdatasets).findIndex(ds => ds.DatasetName === newname)));
    // };

    let content = <>
        <CircularProgress sx={{ m: 2 }} />
        <Typography align='center'>
            Fetching datasets...
        </Typography>
    </>;
    if (isSuccess) {
        if (datasets.filtered.find(ds => ds.DatasetID === selectedID)) {
            const ds = datasets.filtered.find(ds => ds.DatasetID === selectedID);
            content = <>
                <Typography variant='h4' gutterBottom>
                    Overview of the dataset {ds.DatasetName}
                </Typography>
                <FetchAndDisplayData
                    key={'fetchanddisplay-data-' + ds.DatasetID}
                    ds={ds}
                    // handleReSortDatasets={handleReSortDatasets}
                    />
            </>;
        } else {
            content = <Typography color='text.error' align='center'>
                You have no available datasets
            </Typography>;
        }
    } else if (isError) {
        console.error('Failed to fetch datasets: ', error);
        content = <Typography color='text.error' align='center'>Failed to fetch datasets</Typography>;
    }
    return content;
}
