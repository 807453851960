
// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
export function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const RoleOrder = {
    owner: 1,
    admin: 2,
    user: 3,
    none: 4
};

const descendingComparator = (a, b, orderBy) => {
    let transformer;
    if (orderBy === 'AccessRole') {
        transformer = (ds) => {
            const asd = (ds.AccessRole === 'none' && ds.AccessType === 'open' ? '1' : '2');
            return RoleOrder[ds.AccessRole] + asd + ds.DatasetName || Infinity;
        };
    } else {
        transformer = (ds) => ds[orderBy];
    }

    if (transformer(b) < transformer(a)) {
        return -1;
    }
    if (transformer(b) > transformer(a)) {
        return 1;
    }
    return 0;
};

const getComparator = (order, orderBy) => {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
};

export function sortDatasets(datasets, order, orderBy) {
    return stableSort(datasets, getComparator(order, orderBy));
}

const ONE_KB = 1000;
const ONE_MB = 1000 * ONE_KB;
const ONE_GB = 1000 * ONE_MB;

export function bytesToViewString(bytes) {
    if (bytes > ONE_GB) {
        return `${(bytes / ONE_GB).toFixed(2)} GB`;
    } else if (bytes > ONE_MB) {
        return `${(bytes / ONE_MB).toFixed(2)} MB`;
    } else if (bytes > ONE_KB) {
        return `${(bytes / ONE_KB).toFixed(2)} KB`;
    } else {
        return `${bytes} B`;
    }
}
