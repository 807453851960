import axios from 'axios';
import config from '../config/config.json';
const route = `${config.API.url}`;

export const logout = () => new Promise((resolve) => {
    axios.get(`${route}/v1/logout`).then((response) => {
        if (response && response.data === 'ok') {
            return resolve(true);
        } else if (response.code === 401) { // You're also logged out if you're not allowed to logout
            return resolve(true);
        }
    }).catch((error) => { // If you cant reach the server, are you still logged in? maybe but lets throw back to login to be sure
        if (error.response.status === 401) {
            return resolve(true);
        }
        console.log('Error: couldnt reach server' + error);
        return resolve(true);
    });
});

export const requestValidateEmail = (token) => new Promise((resolve, reject) => {
    axios.post(`${route}/v1/emailVerification`, { token }).then((response) => {
        if (response && response.data === 'ok') {
            resolve(true);
        } else {
            reject(response.data);
        }
    }).catch(err => {
        if (err.response.data) {
            reject(err.response.data);
        } else {
            reject(err.message);
        }
    });
});

export const requestAccount = (form) => new Promise((resolve, reject) => {
    axios.post(`${route}/v1/accountRequest`, form).then((response) => {
        if (response && response.data === 'ok') {
            resolve(true);
        } else {
            reject(response.data);
        }
    }).catch(err => {
        if (err.response.data) {
            reject(err.response.data);
        } else {
            reject(err.message);
        }
    });
});

export const fileList = () => new Promise((resolve, reject) => {
    axios.get(`${route}/v1/list`).then((response) => {
        if (response.data) {
            resolve(response.data);
        }
    }).catch((error) => {
        console.log('Error: couldnt reach server' + error);
        return reject(error);
    });
});
export const login = (values) => new Promise((resolve, reject) => {
    axios.post(`${route}/v1/login`, values).then((response) => {
        if (response.data === 'ok') {
            console.log('logged in ok');
            resolve(true);
        } else {
            console.log('Login: ', response.data);
            reject(response);
        }
    }).catch((error) => {
        if (error.response) {
            console.error('Login: ', error.response);
            reject(error.response);
        } else if (error.request) {
            console.error('Login: ', error.request);
            reject(error.request);
        } else {
            reject(error);
        }
    });
});

export const isLoggedIn = () => new Promise((resolve, reject) => {
    axios.get(`${route}/v1/validatesession`).then(resolve).catch(reject);
});

export const meta = (search) => new Promise((resolve, reject) => {
    const newroute = `${route}/v1/meta${search ? `=search=${search}` : ''}`;
    axios.get(newroute).then(resolve).catch(reject);
});
export const putQuery = (query) => new Promise((resolve, reject) => {
    axios.put(`${route}/v1/query`, query).then((response) => {
        resolve(response.data);
    }).catch(reject);
});

export const query = (query) => new Promise((resolve, reject) => {
    axios.get(`${route}/v1/query?id=${query}`).then((response) => {
        resolve(response.data);
    }).catch((error) => {
        console.log('Error: couldnt reach server' + error);
        reject(error);
    });
});

export const distinct = (type) => new Promise((resolve, reject) => {
    axios.get(`${route}/v1/distinct?field=${type}`).then((response) => {
        if (response.data) {
            resolve(response.data);
        }
    }).catch((error) => {
        console.log('Error: couldnt reach server' + error);
        reject(error);
    });
});

export const generateToken = (daystolive) => new Promise((resolve, reject) => {
    axios.get(`${route}/v1/generatetoken?expires=${daystolive}`).then((result) => {
        resolve(result);
    }).catch(reject);
});

export const getMyHistory = () => new Promise((resolve, reject) => {
    axios.get(`${route}/v1/history`).then((response) => resolve(response.data)).catch((err) => {
        console.error(err);
        reject(err);
    });
});
export const getMyQueries = () => new Promise((resolve, reject) => {
    axios.get(`${route}/v1/myqueries`).then((response) => resolve(response.data)).catch((err) => reject(err));
});

export const createQueryGetString = (query) => {
    return `${route}/v1/query?id=${query}`;
};
