// vim: tabstop=4 softtabstop=4 shiftwidth=4

import { Typography, TextField, Box, ButtonGroup, Button, Snackbar } from '@mui/material';
import React, { useState, useCallback, useEffect } from 'react';
import { requestAccount } from '../lib/apiRequests';
// import { Background } from '../lib/styled';
import { GoogleReCaptcha } from 'react-google-recaptcha-v3';

import LoadingButton from '@mui/lab/LoadingButton';

import styles from './SignUp.module.css';

import PropTypes from 'prop-types';

const someCommonDisallowedEmailDomains = [
    'gmail',
    'msn',
    'outlook',
    'yahoo',
    'hotmail'
];

const textFieldObjs = [
    {
        type: 'account',
        label: 'Existing Xerces account name',
        placeholder: 'xxx000, leave empty otherwise',
        required: false
    },
    {
        type: 'firstname',
        label: 'First Name',
        placeholder: '',
        required: true
    },
    {
        type: 'lastname',
        label: 'Last Name',
        placeholder: '',
        required: true
    },
    {
        type: 'email',
        label: 'Email Address',
        placeholder: 'example@org.com',
        required: true
    },
    {
        type: 'organization',
        label: 'Organization',
        placeholder: 'Your company/university',
        required: true
    }
];

const textFieldObjsContact = [
    {
        type: 'contactname',
        label: 'Contact Name',
        placeholder: 'Full Name',
        required: true
    },
    {
        type: 'contactmail',
        label: 'Contact Email Address',
        placeholder: 'example@org.com',
        required: true
    }
];

SignUp.propTypes = {
    returnfunc: PropTypes.func.isRequired,
    setstopreturn: PropTypes.func.isRequired
};

export default function SignUp(props) {
    const [form, setForm] = useState({});
    const [FError, setFError] = useState({});
    const [errorText, setErrorText] = useState('');
    const [snackOpen, setSnackOpen] = useState(false);
    const [spinner, setSpinner] = useState(false);

    // recaptcha
    const [token, setToken] = useState();
    const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);

    function validateForm(form) {
        for (const [key, value] of Object.entries(form)) {
            if (!validateField(key, value)) {
                return false;
            }
        }
        return true;
    }

    function validateEmail(email) {
        if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
            return true;
        }
        return false;
    }

    function checkForbiddenEmailDomains(email) {
        const domain = email.split('@')[1];
        if (someCommonDisallowedEmailDomains.find(d => domain.includes(d))) {
            return domain;
        }
        return false;
    }

    function validateField(key, value) {
        let error = null;
        if (['firstname', 'lastname', 'organization', 'contactname'].includes(key)) {
            if (value.length < 2) {
                error = 'Needs to be atleast 2 letters';
            }
        } else if (['email', 'contactmail'].includes(key)) {
            if (!validateEmail(value)) {
                error = 'Needs to be a valid email address';
            } else {
                const forbiddenDomain = checkForbiddenEmailDomains(value);
                if (forbiddenDomain) {
                    error = 'Not a university nor company address';
                }
            }
        } else if (['phonenumber'].includes(key)) {
            if (value.length > 0) {
                if (!(/^(\+|)\d+$/.test(value))) {
                    error = 'Phone number must be only numbers and can start with "+"';
                } else if (value.length < 6) {
                    error = 'Phone number must be 6 numbers or longer';
                }
            }
        } else if (['account'].includes(key)) {
            if (value.length > 0) {
                if (value.length < 7) {
                    error = 'Must be longer then 7 characters';
                } else if (!(/^[\d\w]+$/.test(value))) {
                    error = 'Must start with a letter and be only letters and numbers';
                }
            }
        } else {
            return true;
        }

        if (error !== null) {
            setFError(FError => ({
                ...FError,
                [key]: {
                    text: error,
                    error: true
                }
            }));
        } else {
            setFError(FError => ({
                ...FError,
                [key]: {}
            }));
        }
        return error === null;
    }

    function formChange(type, value) {
        setForm(form => ({
            ...form,
            [type]: value
        }));

        validateField(type, value);
    }

    const postHandleSendOngoing = (onGoing) => {
        props.setstopreturn(onGoing);
        setSpinner(onGoing);
    };

    function handleSend() {
        setErrorText('');

        if (validateForm(form)) {
            postHandleSendOngoing(true);
            form.recaptchaToken = token;

            requestAccount(form).then(ok => {
                setSnackOpen(true);
                // setRefreshReCaptcha(r => !r);
                postHandleSendOngoing(false);
            }).catch(e => {
                if (typeof v !== 'object') {
                    console.error(e);
                    setErrorText('Unknown error! Please contact the support');
                } else if ('error' in e) {
                    setErrorText(e.error);
                } else if ('errors' in e) {
                    // fix sett errors from server here
                    console.error(e);
                } else {
                    console.error(e);
                    setErrorText('Unknown error! Please contact the support');
                }
                // setErrorText(JSON.stringify(e));
                // refreah here only valid one time
                setRefreshReCaptcha(!refreshReCaptcha);
                postHandleSendOngoing(false);
            });
        } else {
            setErrorText('Fields must be correctly filled');
        }
    }

    function leavePage() {
        setSnackOpen(false);
        props.returnfunc();
    }

    // recaptcha
    const onVerify = useCallback((token) => {
        console.log(token);
        setToken(token);
        setTimeout(function () { setRefreshReCaptcha(r => !r); },
            100 * 1000); // timeout is 2 minutes
    }, []);

    const inputTextField = ({ type, label, placeholder, required }) => {
        return <>
            <div key={`${type}-box`}>
                <TextField
                    key={`${type}-field`}
                    label={label}
                    placeholder={placeholder}
                    disabled={spinner}
                    onChange={(value) => { formChange(type, value.target.value); }}
                    className={styles['text-field']}
                    required={required}
                    error={!FError || ((type in FError) && FError[type].error)}
                />
            </div>
            <div className={styles['error-text-items']} key={`${type}-err`}>
                { FError[type]?.text }
            </div>
        </>;
    };

    useEffect(() => {
        textFieldObjs.concat(textFieldObjsContact).forEach(o => formChange(o.type, ''));
    }, []);

    return <div className={styles['secondary-space']}>
        <Typography variant="h4">Request an account</Typography>
        <div className={styles['text-info']}>
            <Typography variant="body">
                Please fill out the form below to request an account.
                You will need some form of affilition to the WARA-Ops project to be considered.
                <br/>
                <br/>
                <b>Email:</b> Only university or company addresses will be accepted.
            </Typography>
        </div>
        <Box
            sx={{
                '& .MuiTextField-root': { mb: 1, mt: 1, width: '100%' },
                border: '1px'
            }}
            noValidate
            autoComplete="off"
        >
            {textFieldObjs.map(o => <div key={`${o.type}`}>{inputTextField(o)}</div>)}
            <div className={styles['text-info']}>
                <Typography variant="body">
                    <b>Contact person:</b> Please supply name and email to a responsible contact person who
                    can confirm your identity and affiliation to the WARA-Ops project. This could e.g. be your
                    academic supervisor, manager or similar.
                </Typography>
            </div>
            {textFieldObjsContact.map(o => <div key={`${o.type}`}>{inputTextField(o)}</div>)}
            <div className={styles['signup-error-space']}>
                {errorText}
            </div>
            <div>
                <GoogleReCaptcha
                    onVerify={onVerify}
                    refreshReCaptcha={refreshReCaptcha}
                />
            </div>
            <div>
                <ButtonGroup>
                    <LoadingButton loading={ spinner } onClick={handleSend} >Send</LoadingButton>
                    <Button disabled={ spinner } onClick={props.returnfunc} >Cancel</Button>
                </ButtonGroup>
            </div>
        </Box>
        <Snackbar
            open={snackOpen}
            autoHideDuration={3000}
            onClose={() => { setSnackOpen(false); leavePage(); }}
            message="Account requested, You should recieve a confirmation email soon"
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        />
    </div>;
}
