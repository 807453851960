import { React, useState, forwardRef, Fragment } from 'react';
import { useSelector } from 'react-redux';
import { TableVirtuoso } from 'react-virtuoso';
import PropTypes from 'prop-types';

import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';

import { CircularProgress } from '@mui/material';

import { useGetDatasetsQuery, useGetFilesQuery } from '../../state/apiSlice';

function FetchAndDisplayFiles(props) {
    const { id } = props;

    const { data: files, isError: fIsError, error: fError } = useGetFilesQuery(id);

    const [filterBy, setFilterBy] = useState('');

    const filterMatch = (file) => {
        return file.MFileName.includes(filterBy);
    };

    const columns = [
        {
            width: '10%',
            label: 'ID',
            dataKey: 'FileID'
        },
        {
            width: '50%',
            label: 'Name',
            dataKey: 'MFileName'
        },
        {
            width: '20%',
            label: 'Entries',
            dataKey: 'MetricEntries'
        },
        {
            width: '20%',
            label: 'Size',
            dataKey: 'FileSize'
        }
    ];

    function fixedHeaderContent() {
        return (
            <TableRow>
                {columns.map((column) => (
                    <TableCell
                        key={column.dataKey}
                        variant="head"
                        align='center'
                        style={{ width: column.width }}
                    >
                        <div overflow='hidden'>
                            {column.label}
                        </div>
                    </TableCell>
                ))}
            </TableRow>
        );
    }

    function rowContent(_index, row) {
        return (
            <Fragment>
                {columns.map((column) => (
                    <TableCell
                        key={column.dataKey}
                        align='center'
                        >
                        {row[column.dataKey] || '-'}
                    </TableCell>
                ))}
            </Fragment>
        );
    }

    const scrollerFunc = (props, ref) => <TableContainer component={Paper} {...props} ref={ref} />;
    const bodyFunc = (props, ref) => <TableBody {...props} ref={ref} />;

    const VirtuosoTableComponents = {
        Scroller: forwardRef(scrollerFunc),
        Table: (props) => (
            <Table {...props} sx={{ borderCollapse: 'separate' }} size='small' /> // tableLayout: 'fixed'
        ),
        TableHead,
        // eslint-disable-next-line react/prop-types
        TableRow: ({ item: _item, ...props }) => <TableRow {...props} />,
        TableBody: forwardRef(bodyFunc),
    };

    let content = <>
        <CircularProgress sx={{ padding: 2 }} />
        <Typography align='center' sx={{ paddingBottom: 2 }}>
            Loading files...
        </Typography>
    </>;
    let errContent = <></>;

    if (files) {
        if (files.length === 0) {
            content = <></>;
            errContent = <Typography align='center' sx={{ padding: 2 }}>no files found</Typography>;
        } else {
            const filteredFiles = files.filter(file => filterMatch(file));

            // very dirty hack as I could not get autoresize to work.
            let height = filteredFiles.length * 40;
            const maxHeight = 800;
            const minHeight = 200;
            if (height > maxHeight) {
                height = maxHeight;
            } else if (height < minHeight) {
                height = minHeight;
            }

            content = <>
                <TableVirtuoso
                    data={filteredFiles}
                    components={VirtuosoTableComponents}
                    fixedHeaderContent={fixedHeaderContent}
                    itemContent={rowContent}
                    style={{ height, width: '100%' }}
                />
            </>;
        }
    } else if (fIsError) {
        content = <></>;
        console.error('Failed to fetch files: ', fError);
        errContent = <Typography color='text.error' align='center' sx={{ padding: 2 }}>Failed to fetch files</Typography>;
    }

    const handleRequestSearch = (searched) => {
        if (files) {
            setFilterBy(searched);
        }
    };

    // 'max-height': 1000, 'min-height': 100,
    return <>
        <TextField
            id='input-with-icon-textfield'
            label='search'
            InputProps={
                {
                    startAdornment: (
                        <InputAdornment position='start'>
                            <SearchIcon />
                        </InputAdornment>
                    ),
                    placeholder: 'enter file name'
                }
            }
            onInput={(e) => handleRequestSearch(e.target.value)}
            fullWidth
            margin='dense'
            variant='outlined'
            />
        <Paper>
            {content}
            <style>{'html, body, #root { height: 100% }'}</style>
            {errContent}
        </Paper>
    </>;
}

FetchAndDisplayFiles.propTypes = {
    id: PropTypes.number.isRequired
};

export default function Files() {
    const {
        data: datasets,
        isSuccess,
        isError,
        error
    } = useGetDatasetsQuery();

    const selectedID = useSelector(state => state.dataset.selectedID);

    let content = <>
        <CircularProgress sx={{ m: 2 }} />
        <Typography align='center'>
            Fetching datasets...
        </Typography>
    </>;
    if (isSuccess) {
        const ds = datasets.filtered.find(ds => ds.DatasetID === selectedID);
        if (ds) {
            content = <>
                <Typography variant='h4' gutterBottom>
                    Browse the files in {ds.DatasetName}
                </Typography>
                <FetchAndDisplayFiles
                    key={'fetchanddisplay-files-' + selectedID}
                    id={selectedID}
                    />
            </>;
        } else {
            content = <Typography color='text.error' align='center'>You have no available datasets</Typography>;
        }
    } else if (isError) {
        console.error('Failed to fetch files: ', error);
        content = <Typography color='text.error' align='center'>Failed to fetch files</Typography>;
    }
    return content;
}
