import { createSlice } from '@reduxjs/toolkit';

export const chartQuerySlice = createSlice({
    name: 'chartQuery',
    initialState: {
        value: [],
        query: '',
        hostSelect: 'all',
        stopDate: new Date(),
        nameSelect: '',
        startDate: new Date(new Date().setDate(new Date().getDate() - 2000)),
        queryChanged: false
    },
    reducers: {
        setQuery: (state, query) => {
            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the state because it uses the Immer library,
            // which detects changes to a "draft state" and produces a brand new
            // immutable state based off those changes
            state.query = query.payload;
        },
        setHostSelect: (state, host) => {
            if (state.hostSelect !== host.payload) {
                state.hostSelect = host.payload;
                state.queryChanged = true;
            }
        },
        setNameSelect: (state, name) => {
            if (state.nameSelect !== name.payload) {
                state.nameSelect = name.payload;
                state.queryChanged = true;
            }
        },
        updateValue: (state, value) => {
            state.value = value.payload;
            state.queryChanged = false;
        },
    },
});

// Action creators are generated for each case reducer function
export const { setQuery, updateValue, setNameSelect, setHostSelect } = chartQuerySlice.actions;

export default chartQuerySlice.reducer;
