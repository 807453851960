import React, { Fragment, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Collapse from '@mui/material/Collapse';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import Dialog from '@mui/material/Dialog';
// import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import ButtonGroup from '@mui/material/ButtonGroup';
import LoadingButton from '@mui/lab/LoadingButton';
import { CircularProgress } from '@mui/material';
// import Avatar from '@mui/material/Avatar';
import { useTheme } from '@mui/material/styles';

import { visuallyHidden } from '@mui/utils';

import { sortDatasets } from '../../lib/dataTransforms';
import { delayedLockWrapCreator } from '../../lib/locks';
import {
    useGetDatasetsQuery,
    usePostRequestAccessMutation,
    useDeleteRequestAccessMutation
} from '../../state/apiSlice';
import { getLogo, printOrgName } from '../../lib/styled';

import styles from './DatasetCatalog.module.css';

const headCellsOptions = {
    sm: {
        sortable: [
            {
                title: 'Name',
                id: 'DatasetName',
                sortDir: 'asc', // default first sort direction when sorting by this column
                width: '39%'
            },
            {
                title: 'Org.',
                id: 'Organization',
                sortDir: 'asc',
                width: '33%'
            },
            {
                title: 'Role',
                id: 'AccessRole',
                sortDir: 'asc',
                width: '27%'
            }
        ],
        descriptive: [
        ]
    },
    md: {
        sortable: [
            {
                title: 'Dataset name',
                id: 'DatasetName',
                sortDir: 'asc', // default first sort direction when sorting by this column
                width: '20%'
            },
            {
                title: 'Organization',
                id: 'Organization',
                sortDir: 'asc',
                width: '18%'
            },
            {
                title: 'User role',
                id: 'AccessRole',
                sortDir: 'asc',
                width: '12%'
            }
        ],
        descriptive: [
            {
                title: 'Short Description',
                id: 'ShortInfo',
                width: '29%'
            },
            {
                title: 'Tags',
                id: 'Tags',
                width: '15%'
            }
        ]
    },
    default: {
        sortable: [
            {
                title: 'Dataset name',
                id: 'DatasetName',
                sortDir: 'asc', // default first sort direction when sorting by this column
                width: '15%'
            },
            {
                title: 'Organization',
                id: 'Organization',
                sortDir: 'asc',
                width: '15%'
            },
            {
                title: 'User role',
                id: 'AccessRole',
                sortDir: 'asc',
                width: '10%'
            },
            {
                title: 'Access',
                id: 'AccessType',
                sortDir: 'asc',
                width: '10%'
            },
            {
                title: 'Creation date',
                id: 'CreateDate',
                sortDir: 'desc',
                width: '12%'
            }
        ],
        descriptive: [
            {
                title: 'Short description',
                id: 'ShortInfo',
                width: '18%'
            },
            {
                title: 'Tags',
                id: 'Tags',
                width: '15%'
            }
        ]
    }
};

const printIdMap = {
    Organization: (ds) => printOrgName(ds.Organization),
    CreateDate: (ds) => ds.CreateDate.toLocaleDateString(),
    Tags: (ds) => ds.Tags.join(', '),
    AccessRole: (ds) => {
        let role = ds.AccessRole;
        if (ds.AccessRole === 'none') {
            role = '-';
        }
        return role;
    },
    AccessType: (ds) => {
        if (ds.AccessType === 'open') {
            return 'open';
        } else if (ds.AccessType === 'closed') {
            return 'restricted';
        } else {
            return ds.AccessType;
        }
    }
};

function AccessRequest(props) {
    const { note, date, name, id } = props;

    const [postRequestAccess] = usePostRequestAccessMutation();
    const [deleteRequestAccess] = useDeleteRequestAccessMutation();
    const [updating, setUpdating] = useState(false);

    const [open, setOpen] = useState(false);
    const [requestNote, setRequestNote] = useState('');
    const [noteError, setNoteError] = useState('');
    const [dispatchLock, setDispatchLock] = useState(false);

    // The delay prevents us from clicking buttons in fading dialog windows
    const dispatchLockWrap = delayedLockWrapCreator(
        dispatchLock,
        setDispatchLock,
        useTheme().transitions.duration.leavingScreen
    );

    const handleClickOpen = () => {
        setRequestNote('');
        setNoteError('');
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const postRequestOngoing = (onGoing) => {
        setUpdating(onGoing);
    };

    const handlePostRequest = async () => {
        if (requestNote) {
            postRequestOngoing(true);
            setNoteError('');
            await dispatchLockWrap(async () => {
                try {
                    await postRequestAccess({ datasetid: id, note: requestNote }).unwrap();
                    handleClose();
                } catch (err) {
                    console.error(err);
                    setNoteError('Failed to request access: ' + err.data);
                }
            });
            postRequestOngoing(false);
        } else {
            setNoteError('please enter an explanation');
        }
    };

    const handleDeleteRequest = async () => {
        setUpdating(true);
        await dispatchLockWrap(async () => {
            try {
                await deleteRequestAccess({ datasetid: id }).unwrap();
            } catch (err) {
                console.error(err);
            }
        });
        setUpdating(false);
    };

    const hasRequest = !!(note && date);

    let content;
    if (hasRequest) {
        // on click remove access
        content = <Chip
            variant="outlined"
            onDelete={handleDeleteRequest}
            label="Access requested"
            disabled={updating}
        />;
    } else {
        content = <>
            <Chip variant="outlined" icon={<LockOpenIcon />} onClick={handleClickOpen} label="Request access"/>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Request access to  {name}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <i>Sending an access request will give your name and email address to the owner and admins of this dataset</i>
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Explain why you would want access to this data"
                        fullWidth
                        multiline
                        rows={4}
                        onChange={(event) => setRequestNote(event.target.value)}
                        helperText={noteError || ' '}
                        error={!!noteError}
                        disabled={updating}
                    />
                </DialogContent>
                <div style={{ alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
                    <ButtonGroup sx={{ mb: 3 }}>
                        <LoadingButton loading={updating} onClick={handlePostRequest}>
                            <span>Request</span>
                        </LoadingButton>
                        <Button disabled={updating} onClick={handleClose}>
                            Cancel
                        </Button>
                    </ButtonGroup>
                </div>
            </Dialog>
        </>;
    }

    return content;
}

function Row(props) {
    const { ds, selectedID, mediaBreakpoint, columns } = props;
    const [open, setOpen] = useState(false);

    const printIsSelected = (s) => selectedID === ds.DatasetID ? <b>{s}</b> : s;

    const customTypography = (text) =>
        <Typography variant='body' style={{ overflow: 'auto', whiteSpace: 'normal', wordBreak: 'break-word' }}>
            {printIsSelected(text)}
        </Typography>;

    const customTableCell = (col) => {
        const text = (col in printIdMap ? printIdMap[col](ds) : ds[col]);

        if (mediaBreakpoint === 'sm') {
            return <TableCell padding='checkbox' style={{ paddingLeft: 16 }} align='left' key={ds.DatasetID + '_' + col}>
                {customTypography(text)}
            </TableCell>;
        } else {
            return <TableCell align='left' key={ds.DatasetID + '_' + col}>
                {customTypography(text)}
            </TableCell>;
        }
    };

    let accessButton = <></>;
    if (ds.AccessRole === 'none' && ds.AccessType === 'closed') {
        accessButton = <AccessRequest
            note={ds.AccessRequest.Note}
            date={ds.AccessRequest.CreateDate}
            name={ds.DatasetName}
            id={ds.DatasetID} />;
    } else if (ds.AccessType === 'open') {
        accessButton = <Chip
            icon={<Diversity3Icon />}
            variant="outlined"
            label="Open access" />;
    }

    return (
        <Fragment>
            <TableRow
                onClick={() => setOpen(!open)}
                hover
                >
                <TableCell padding='checkbox' align='center'>
                    {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </TableCell>
                {!(mediaBreakpoint === 'sm')
                    ? <TableCell padding='checkbox'align='center'>
                        <img src={getLogo(ds.Organization)} className={styles['table-logo']}/>
                    </TableCell>
                    : <></>}
                {columns.map(col => customTableCell(col))}
            </TableRow>
            <TableRow // sx={{ borderBottom: 2 }}
                >
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12} >
                    <Collapse in={open} timeout='auto' unmountOnExit>
                        <Grid container justifyContent="space-around" alignItems="center">
                            <Grid item xs={0} md={1} />
                            <Grid item xs={12} md={8}>
                                <Typography variant='h6' sx={{ ml: 1 }}>
                                    Information
                                </Typography>
                                <Box style={{ whiteSpace: 'pre-line' }} sx={{ margin: 1 }}>
                                    <Typography variant='body'>
                                        {ds.LongInfo}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={3} >
                                <Box sx={{ margin: 1 }} display="flex" justifyContent="center" alignItems="center">
                                    {accessButton}
                                </Box>
                            </Grid>
                        </Grid>
                    </Collapse>
                </TableCell>
            </TableRow>
        </Fragment>
    );
}

function HeadCell(props) {
    const { id, colname, width, order, orderBy, sortDir, onRequestSort } = props;

    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property, sortDir);
    };

    const tableStyle = { width, paddingLeft: 16, paddingTop: 8, paddingBottom: 8 };

    return (
        <TableCell
            className={styles['table-cell']}
            key={id}
            align='left'
            padding='checkbox'
            sortDirection={orderBy === id ? order : false}
            style={tableStyle}
            >
            <TableSortLabel
                active={orderBy === id}
                direction={orderBy === id ? order : sortDir}
                onClick={createSortHandler(id)}
                >
                <Typography variant='body' style={{ overflow: 'auto', whiteSpace: 'normal', wordBreak: 'break-word' }}>
                    {colname}
                </Typography>
                {orderBy === id
                    ? (
                        <Box component='span' sx={visuallyHidden}>
                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                        </Box>
                    )
                    : null}
            </TableSortLabel>
        </TableCell>
    );
}

AccessRequest.propTypes = {
    date: PropTypes.instanceOf(Date),
    note: PropTypes.string,
    name: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired
};

Row.propTypes = {
    ds: PropTypes.shape({
        DatasetName: PropTypes.string.isRequired,
        CreateDate: PropTypes.instanceOf(Date).isRequired,
        ShortInfo: PropTypes.string.isRequired,
        LongInfo: PropTypes.string.isRequired,
        DatasetID: PropTypes.number.isRequired,
        Organization: PropTypes.string.isRequired,
        AccessRole: PropTypes.string.isRequired,
        AccessType: PropTypes.string.isRequired,
        AccessRequest: PropTypes.shape({
            CreateDate: PropTypes.instanceOf(Date),
            Note: PropTypes.string
        }),
        Tags: PropTypes.array.isRequired

    }).isRequired,
    columns: PropTypes.array.isRequired,
    selectedID: PropTypes.number.isRequired,
    mediaBreakpoint: PropTypes.string.isRequired
};

HeadCell.propTypes = {
    id: PropTypes.string.isRequired,
    colname: PropTypes.string.isRequired,
    width: PropTypes.string.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    sortDir: PropTypes.oneOf(['asc', 'desc']).isRequired,
    onRequestSort: PropTypes.func.isRequired,
};

export default function DatasetCatalog() {
    const {
        data: datasets,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetDatasetsQuery();

    const selectedID = useSelector(state => state.dataset.selectedID);

    const mediaSmall = useMediaQuery((theme) => theme.breakpoints.down('md'));
    const mediaMedium = useMediaQuery((theme) => theme.breakpoints.down('lg'));

    let headCells;
    let mediaBreakpoint;
    if (mediaSmall) {
        headCells = headCellsOptions.sm;
        mediaBreakpoint = 'sm';
    } else if (mediaMedium) {
        headCells = headCellsOptions.md;
        mediaBreakpoint = 'md';
    } else {
        headCells = headCellsOptions.default;
        mediaBreakpoint = '';
    }

    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState(headCells.sortable[0].id);
    const [filterBy, setFilterBy] = useState('');

    const handleRequestSort = (event, property, sortDir) => {
        let direction;
        if (orderBy === property) {
            direction = order === 'asc' ? 'desc' : 'asc';
        } else {
            direction = sortDir;
        }
        setOrder(direction);
        setOrderBy(property);
    };

    const handleRequestSearch = (searched) => {
        if (isSuccess) {
            setFilterBy(searched);
        }
    };

    const filterMatch = (ds, filterBy) => {
        return ds.DatasetName.includes(filterBy) || ds.Tags.some(tag => tag.includes(filterBy));
    };

    let content;
    if (isLoading) {
        content = [];
    } else if (isSuccess) {
        content = sortDatasets(datasets.raw, order, orderBy).filter(ds => filterMatch(ds, filterBy));
    } else if (isError) {
        console.log(error);
        content = [];
    }

    return (
        <>
            <Typography variant='h4' gutterBottom>
                Dataset catalog
            </Typography>
            <TextField
                id='input-with-icon-textfield'
                label='search'
                InputProps={
                    {
                        startAdornment: (
                            <InputAdornment position='start'>
                                <SearchIcon />
                            </InputAdornment>
                        ),
                        placeholder: 'enter a dataset name or tag'
                    }
                }
                onInput={(e) => handleRequestSearch(e.target.value)}
                fullWidth
                margin='dense'
                variant='outlined'
            />
            <TableContainer component={Paper} sx={{ mb: 1 }}>
                <Table aria-label='datasettable'>
                    <TableHead>
                        <TableRow>
                            <TableCell width='1%'/>
                            { !mediaSmall ? <TableCell width='5%'/> : <></>}
                            {headCells.sortable.map(o => (
                                <HeadCell
                                    key={o.id}
                                    id={o.id}
                                    width={o.width}
                                    colname={o.title}
                                    order={order}
                                    orderBy={orderBy}
                                    sortDir={o.sortDir}
                                    onRequestSort={handleRequestSort}
                                />
                            ))}
                            {headCells.descriptive.map(o => (
                                <TableCell
                                    className={styles['table-cell']}
                                    key={o.title}
                                    padding='checkbox'
                                    width={o.width}
                                    style={{ paddingLeft: 16, paddingTop: 8, paddingBottom: 8 }}
                                    >
                                    {o.title}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {content.map(ds =>
                            <Row
                                key={ds.DatasetID}
                                ds={ds}
                                columns={headCells.sortable.map(o => o.id).concat(headCells.descriptive.map(o => o.id))}
                                selectedID={selectedID}
                                mediaBreakpoint={mediaBreakpoint}
                            />
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            {isLoading
                ? <>
                    <CircularProgress sx={{ m: 2 }} />
                    <Typography align='center'>
                        {'Fetching datasets...'}
                    </Typography>
                </>
                : <></>}
        </>
    );
}
