import { createSlice } from '@reduxjs/toolkit';

export const distinctNameSlice = createSlice({
    name: 'distinctName',
    initialState: {
        value: []

    },
    reducers: {
        setDistinctName: (state, action) => {
            state.value = action.payload;
        },
        clearDistinctName: (state) => {
            state.value = [];
        },
    },
});

// Action creators are generated for each case reducer function
export const { setDistinctName, clearDistinctName } = distinctNameSlice.actions;

export default distinctNameSlice.reducer;
