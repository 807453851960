import { createSlice } from '@reduxjs/toolkit';

export const myQueries = createSlice({
    name: 'myQueries',
    initialState: {
        queries: [],
        current: null
    },
    reducers: {
        setMyQueries: (state, action) => {
            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the state because it uses the Immer library,
            // which detects changes to a "draft state" and produces a brand new
            // immutable state based off those changes
            state.queries = action.payload;
        },
        addQuery: (state, action) => {
            const tmp = JSON.parse(JSON.stringify(state.queries));
            tmp.push(action.payload);
            state.queries = tmp;
        }
    },
});

// Action creators are generated for each case reducer function
export const { setMyQueries, addQuery } = myQueries.actions;

export default myQueries.reducer;
