import { createSlice } from '@reduxjs/toolkit';

const tabSelectSlice = createSlice({
    name: 'tabSselect',
    initialState: {
        value: 0
    },
    reducers: {
        setValue(state, action) {
            state.value = action.payload;
        }
    }
});

export const { setValue } = tabSelectSlice.actions;

export default tabSelectSlice.reducer;
