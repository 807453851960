import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { useDispatch } from 'react-redux';

const Logout = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const logout = () => {
        return new Promise((resolve, reject) => {
            dispatch({
                type: 'USER_LOGOUT',
            });
            resolve();
        });
    };

    useEffect(() => {
        logout()
            .then(() => navigate('/home', { replace: true }));
    }, []);

    return <CircularProgress />;
};

export default Logout;
