export const timeout = ms => new Promise(resolve => setTimeout(resolve, ms));

export function delayedLockWrapCreator(lock, setLock, delay) {
    return async (cb) => {
        if (!lock) {
            setLock(true);
            await cb();
            await timeout(delay);
            setLock(false);
        }
    };
}
