import { createSlice } from '@reduxjs/toolkit';

export const loginSlice = createSlice({
    name: 'login',
    initialState: {
        value: false,
        user: 'unknown'

    },
    reducers: {
        loggedIn: (state, signum) => {
            state.value = true;
            state.user = signum.payload;
        },
        loggedOut: (state) => {
            state.value = false;
        },
    },
});

// Action creators are generated for each case reducer function
export const { loggedIn, loggedOut } = loginSlice.actions;

export default loginSlice.reducer;
