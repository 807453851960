import { createSlice } from '@reduxjs/toolkit';

export const fileListSlice = createSlice({
    name: 'fileList',
    initialState: {
        value: null

    },
    reducers: {
        setFileList: (state, action) => {
            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the state because it uses the Immer library,
            // which detects changes to a "draft state" and produces a brand new
            // immutable state based off those changes
            state.value = action.payload;
        },
        clearFileList: (state) => {
            state.value = [];
        }
    },
});

// Action creators are generated for each case reducer function
export const { setFileList, clearFileList } = fileListSlice.actions;

export default fileListSlice.reducer;
