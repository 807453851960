// vim: tabstop=4 softtabstop=4 shiftwidth=4

import { Typography, Box, CircularProgress } from '@mui/material';
import React, { useLayoutEffect, useState } from 'react';
// import { useHistory, useLocation } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { requestValidateEmail } from '../lib/apiRequests';
import { Background } from '../lib/styled';

import styles from './SignUp.module.css';

const RequestAccountConfirm = () => {
    // const history = useHistory();
    const location = useLocation();
    const [spinner, setSpinner] = useState(false);
    const [errorText, setErrorText] = useState('');
    const [goodText, setGoodText] = useState('');

    function setText(gText, etext) {
        setErrorText(etext);
        setGoodText(gText);
    }

    useLayoutEffect(() => {
        const token = new URLSearchParams(location.search).get('token');

        const verifyEmail = async () => {
            setSpinner(true);
            try {
                const response = await requestValidateEmail(token);

                console.log('Email verified successfully!', response.data);
                setText('Email verified successfully, your request have been sent!');
                // Redirect the user to a success page or perform other actions
                // history.push('/verification-success');
            } catch (error) {
                console.error('Failed to verify email:', error);
                setText('', 'Failed to verify email');
            }
            setSpinner(false);
        };

        if (token) {
            verifyEmail();
        } else {
            console.error('Email verification token not found!');
            setText('', 'no token to verify');
            // history.push('/verification-error');
        }
    }, [/* history, */location.search]);

    return <div className={styles['secondary-space-pos']}>
        <div className={styles['secondary-space']}>
            <Background>
                <Typography variant="h4">Verifying email</Typography>
                <Box component="form"
                    sx={{ '& .MuiTextField-root': { m: 1, width: '25ch' }, border: '1px' }}
                    noValidate
                    autoComplete="off"
                 >
                    <div className={styles['spinner-space']}>
                        { spinner && <CircularProgress /> }
                    </div>
                    <div className={styles['text-space']}>
                        <div className={styles['text-ok-space']}>
                            { goodText }
                        </div>
                        <div className={styles['text-error-space']}>
                            { errorText }
                        </div>
                    </div>
                </Box>
            </Background>
        </div>
    </div>;
};

export default RequestAccountConfirm;
