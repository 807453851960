import { React, useState, useEffect } from 'react';
import { Typography, CardContent, Divider, Grid, Card, CardMedia, Button, Dialog } from '@mui/material';
import PropTypes from 'prop-types';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import HandshakeOutlinedIcon from '@mui/icons-material/HandshakeOutlined';
import styles from './frontpage.module.css';
import ImageCarousel from './imageCarousel';

import SignUp from './SignUp';

function InfoCard(props) {
    const { title, content, icon } = props;
    return <Card sx={{ minWidth: '225px', maxWidth: '300px', height: '100%' }} align='center'>
        <CardContent>
            <Grid container direction="column" justifyContent="center" alignItems="center">
                <Grid item>
                    {icon}
                </Grid>
                <Grid item>
                    <Typography variant="h4">
                        {title}
                    </Typography>
                </Grid>
            </Grid>
            <Divider variant="middle" sx={{ mb: 1, mt: 1 }}/>
            {content}
        </CardContent>
    </Card>;
}

InfoCard.propTypes = {
    title: PropTypes.string,
    content: PropTypes.object.isRequired,
    icon: PropTypes.object.isRequired,
};

const iconSize = 50;

function FrontPage(props) {
    const [open, setOpen] = useState(false);
    const [stopToggle, setStopToggle] = useState(false);

    const queryParameters = new URLSearchParams(window.location.search);
    const signupdialog = queryParameters.get('signupdialog');

    const handleOpen = () => {
        if (!stopToggle) {
            setOpen(true);
        }
    };

    const handleClose = () => {
        if (!stopToggle) {
            setOpen(false);
        }
    };

    useEffect(() => {
        if (signupdialog && !open) {
            handleOpen();
        }
    }, []);

    return (<>
        <div id="frontend-root" className={styles['flex-item']}>
            <div className={styles['present-space']}>
                <Card sx={{ padding: { xs: '10px', md: '1rem' }, margin: '1rem', marginLeft: { xs: 0 }, marginRight: { xs: 0 } }}>
                    <CardContent>
                        <Typography variant='h4'>
                            WASP Research Arena - Operational Data
                        </Typography>
                        <Typography variant='subtitle1'>
                            funded by the Wallenberg AI, Autonomous Systems and Software Program
                        </Typography>
                    </CardContent>
                    <CardMedia>
                        <ImageCarousel />
                    </CardMedia>
                </Card>
                <Grid container direction='row' justifyContent="center" spacing={2} alignItems='stretch' >
                    <Grid item xs={12} sm={6} lg={3} align='center'>
                        <InfoCard
                            icon={<CloudUploadOutlinedIcon sx={{ fontSize: iconSize }}/>}
                            title='Host Data'
                            content={<Typography variant="body">
                                Safe data sharing for swedish industry. Access must be explicitly given to users
                                and data never leaves ERDC during the normal workflow.
                            </Typography>}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3} align='center'>
                        <InfoCard
                            icon={<GroupsOutlinedIcon sx={{ fontSize: iconSize }}/>}
                            title='Collaborate'
                            content={<Typography variant="body">
                                Give data access to researchers to allow them to browse the data, and process it in
                                a integrated, GPU-accelerated compute platform
                            </Typography>}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3} align='center'>
                        <InfoCard
                            icon={<LockOpenOutlinedIcon sx={{ fontSize: iconSize }}/>}
                            title='Access'
                            content={ <Grid container direction='column' alignItems="stretch" justifyContent='center' spacing={1}>
                                <Grid item sx={{ textAlign: 'center' }}>
                                    <Button variant="outlined" onClick={handleOpen} className={styles['sell-button']}>
                                        Request account
                                    </Button>
                                </Grid>
                            </Grid>}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3} align='center'>
                        <InfoCard
                            icon={<HandshakeOutlinedIcon sx={{ fontSize: iconSize }}/>}
                            title='Partner Up'
                            content={<Typography variant="body" style={{ whiteSpace: 'pre-line' }}>
                                Want to host data in WARA-Ops? Send email to <a href="mailto:johan.eker@ericsson.com">Johan Eker</a>
                            </Typography>}
                        />
                    </Grid>
                </Grid>
            </div>
        </div>
        <Dialog open={open} onClose={handleClose}>
            <div className={styles['dialog-frame']}>
                <SignUp returnfunc={handleClose} setstopreturn={setStopToggle} />
            </div>
        </Dialog>
    </>);
}
export default FrontPage;
