import { createSlice } from '@reduxjs/toolkit';
import randomWords from 'random-words';
const parseArrayOrReturnEmptyArray = (array) => {
    try {
        const parsed = JSON.parse(array);
        return parsed;
    } catch (e) {
        return [];
    }
};
export const downloadQuerySlice = createSlice({
    name: 'downloadQuery',
    initialState: {
        value: [],
        query: '',
        hostSelect: [],
        nameSelect: [],
        slice: [],
        startDate: new Date(new Date().setDate(new Date().getDate() - 2000)),
        stopDate: new Date(),
        limit: 1000,
        format: 'json',
        zipped: true,
        queryChanged: false,
        isDownloading: false,
        QueryID: null,
        qname: randomWords({ exactly: 2, join: '' })
    },
    reducers: {
        setdownloadQuery: (state, query) => {
            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the state because it uses the Immer library,
            // which detects changes to a "draft state" and produces a brand new
            // immutable state based off those changes
            state.query = query.payload;
        },
        setFormat: (state, value) => {
            state.format = value.payload;
        },
        setLimit: (state, value) => {
            state.limit = value.payload;
        },
        setIsDownloading: (state, value) => {
            state.isDownloading = value.payload;
        },
        setDownloadHostSelect: (state, host) => {
            if (state.hostSelect !== host.payload) {
                state.hostSelect = host.payload;
                state.queryChanged = true;
            }
        },
        setDownloadNameSelect: (state, name) => {
            if (state.nameSelect !== name.payload) {
                state.nameSelect = name.payload;
                state.queryChanged = true;
            }
        },
        setStartDate: (state, value) => {
            state.startDate = value.payload;
        },
        setStopDate: (state, value) => {
            state.stopDate = value.payload;
        },
        updateValue: (state, value) => {
            state.value = value.payload;
            state.queryChanged = false;
        },
        setSlice: (state, value) => {
            state.slice = value.payload;
        },
        setQname: (state, value) => {
            state.qname = value.payload;
        },
        setQueryID: (state, value) => {
            state.QueryID = value.payload;
        },
        setAll: (state, value) => {
            const pl = value.payload;
            // Arrays
            state.hostSelect = parseArrayOrReturnEmptyArray(pl.host);
            state.slice = parseArrayOrReturnEmptyArray(pl.slice);
            state.nameSelect = parseArrayOrReturnEmptyArray(pl.name);
            // Dates
            state.startDate = new Date(pl.start);
            state.stopDate = new Date(pl.stop);
            // strings
            state.format = pl.format;
            state.qname = pl.qname;
            // Integers
            state.QueryID = parseInt(pl.QueryID);
            state.zipped = !!pl.zipped;
            state.limit = parseInt(pl.limit);
            // functional
            state.queryChanged = false;
            state.isDownloading = false;
        }
    },
});

// Action creators are generated for each case reducer function
export const { setAll, setQueryID, setQname, setSlice, setdownloadQuery, setIsDownloading, setFormat, setLimit, setDownloadHostSelect, setDownloadNameSelect, updateValue, setStartDate, setStopDate } = downloadQuerySlice.actions;

export default downloadQuerySlice.reducer;
