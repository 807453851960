import { createSlice } from '@reduxjs/toolkit';

export const MyDownloadsSlice = createSlice({
    name: 'myDownloads',
    initialState: {
        queries: [],
        files: [],

    },
    reducers: {
        setQueries: (state, action) => {
            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the state because it uses the Immer library,
            // which detects changes to a "draft state" and produces a brand new
            // immutable state based off those changes
            state.queries = action.payload;
        },
        setFiles: (state, action) => {
            state.files = action.payload;
        }
    },
});

// Action creators are generated for each case reducer function
export const { setQueries, setFiles } = MyDownloadsSlice.actions;

export default MyDownloadsSlice.reducer;
