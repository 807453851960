import { createSlice } from '@reduxjs/toolkit';

export const metaSlice = createSlice({
    name: 'meta',
    initialState: {
        query: null,
        results: null,
        base: null

    },
    reducers: {
        setQuery: (state, action) => {
            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the state because it uses the Immer library,
            // which detects changes to a "draft state" and produces a brand new
            // immutable state based off those changes
            state.query = action.payload;
        },
        setResults: (state, action) => {
            state.results = action.payload;
        },
        setBase: (state, action) => {
            state.base = action.payload;
        }
    },
});

// Action creators are generated for each case reducer function
export const { setQuery, setResults, setBase } = metaSlice.actions;

export default metaSlice.reducer;
