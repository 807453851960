import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import loginReducer from './state/loginSlice';
import distinctNameReducer from './state/distinctNameSlice';
import distinctHostReducer from './state/distinctHostSlice';
import chartQueryReducer from './state/chartQuerySlice';
import fileListReducer from './state/fileListSlice';
import metaReducer from './state/metaSlice';
import downloadQueryReducer from './state/downloadQuerySlice';
import MyDownloadsReducer from './state/myDownloadsSlice';
import myQueriesReducer from './state/myQueriesSlice';
import datasetReducer from './state/datasetSlice';
import tabSelectReducer from './state/tabSelectSlice';
import { apiSlice } from './state/apiSlice';

const userReducer = combineReducers(
    {
        login: loginReducer,
        distinctName: distinctNameReducer,
        distinctHost: distinctHostReducer,
        chartQuery: chartQueryReducer,
        fileList: fileListReducer,
        meta: metaReducer,
        downloadQuery: downloadQueryReducer,
        myDownloads: MyDownloadsReducer,
        myQueries: myQueriesReducer,
        dataset: datasetReducer,
        tabSelect: tabSelectReducer,
        [apiSlice.reducerPath]: apiSlice.reducer
    });

const rootReducer = (state, action) => {
    if (action.type === 'USER_LOGOUT') {
        return userReducer(undefined, action);
    }
    return userReducer(state, action);
};

// Dont save API calls from RTK Query, datasets or tabSelect between reloads.
// In the future, maybe replace with a whitelist for login to keep sessions between
// reloads but not the rest.
const persistConfig = {
    key: 'root',
    storage,
    blacklist: [apiSlice.reducerPath, 'dataset', 'tabSelect'],
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }).concat(apiSlice.middleware)
});
const persistor = persistStore(store);

export {
    store, persistor
};
