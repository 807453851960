import { React, useState, useEffect } from 'react';
import { Button, Grid, Typography } from '@mui/material';
import { motion, AnimatePresence } from 'framer-motion';
import { wrap } from 'popmotion';

import styles from './imageCarousel.module.css';

const variants = {
    enter: (direction) => {
        return {
            zIndex: 1,
            x: direction > 0 ? '100%' : '-100%',
            opacity: 1
        };
    },
    center: {
        zIndex: 2,
        x: 0,
        opacity: 1
    },
    exit: (direction) => {
        return {
            zIndex: 0,
            x: direction < 0 ? '100%' : '-100%',
            opacity: 1
        };
    }
};

const images = [
    {
        src: './opsroom.jpg',
        textUpper: 'Upload your ops data',
        textLower: 'Decide who can view it'
    },
    {
        src: './ess_areal.jpg',
        textUpper: 'Become a partner',
        textLower: 'Collaborate with researchers'
    },
    {
        src: './digitalfabric.jpg',
        textUpper: 'Explore datasets',
        textLower: 'Discover actionable insights'
    },
    {
        src: './dc.jpg',
        textUpper: 'Data storage and Compute',
        textLower: 'In the same location at ERDC in Lund'
    }
];

export default function ImageCarousel() {
    const [[page, direction], setPage] = useState([0, 0]);
    const [imgsLoading, setImgsLoading] = useState(true);
    const [isAnimating, setIsAnimating] = useState(false);

    useEffect(() => {
        const timerID = setTimeout(() => paginate(1), 10000);
        return () => clearTimeout(timerID);
    });

    useEffect(() => {
        // prefetch all images
        images.forEach((imgobj, idx) => {
            const photo = new Image();
            photo.src = imgobj.src;

            const timeout = setTimeout(() => {
                console.warn(`Image ${imgobj.src} not loading`);
                if (idx === 0) {
                    setImgsLoading(false);
                }
            }, 2000);

            photo.onload = () => {
                clearTimeout(timeout);

                // start displaying image carousel as soon as the first image has loaded
                if (idx === 0) {
                    setImgsLoading(false);
                }
            };
        });
    }, []);

    const imageIndex = wrap(0, images.length, page);

    const paginate = (newDirection) => {
        setIsAnimating(true);
        setPage([page + newDirection, newDirection]);
    };

    let content = <div className={styles['slideshow-blank']} />;
    if (!imgsLoading) {
        content = <div className={styles.slideshow}>
            <AnimatePresence initial={false} custom={direction} onExitComplete={() => setIsAnimating(false)}>
                <motion.div
                    key={page + 'box'}
                    custom={direction}
                    variants={variants}
                    initial="enter"
                    animate="center"
                    exit="exit"
                    className={styles['motion-box']}
                    transition={{
                        x: { type: 'spring', duration: 2, bounce: 0 },
                    }}
                    >
                    <motion.img key={page + 'img'} src={images[imageIndex].src} className={styles['motion-box-img']}/>
                    <motion.div key={page + 'text-upper'} className={styles['motion-box-text-upper']}>
                        <Typography variant='h4' sx={{ fontSize: '3vw' }}>
                            {images[imageIndex].textUpper}
                        </Typography>
                    </motion.div>
                    <motion.div key={page + 'text-lower'} className={styles['motion-box-text-lower']}>
                        <Typography variant='h4' sx={{ fontSize: '3vw' }}>
                            {images[imageIndex].textLower}
                        </Typography>
                    </motion.div>
                </motion.div>
            </AnimatePresence>
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
            >
                <Grid item>
                    <Button
                        variant="outlined"
                        color="secondary"
                        className={styles.prev}
                        onClick={() => {
                            if (!isAnimating) {
                                paginate(-1);
                            }
                        }}
                        >
                        {'<'}
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        variant="outlined"
                        color="secondary"
                        className={styles.next}
                        onClick={() => {
                            if (!isAnimating) {
                                paginate(1);
                            }
                        }}
                        >
                        {'>'}
                    </Button>
                </Grid>
            </Grid>
        </div>;
    }

    return content;
}
