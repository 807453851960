import { createSlice } from '@reduxjs/toolkit';

const datasetSlice = createSlice({
    name: 'dataset',
    initialState: {
        selectedID: -1
    },
    reducers: {
        setSelectedID(state, action) {
            state.selectedID = action.payload;
        }
    }
});

export const { setSelectedID } = datasetSlice.actions;

export default datasetSlice.reducer;
