import React from 'react';
import { Typography } from '@mui/material';

import styles from './About.module.css';

export default function About() {
    return (
        <div className={styles['text-format']}>
            <Typography variant='h5' paragraph>
                The Dataportal
            </Typography>
            <Typography variant='body1' paragraph>
                This portal is part of WARA-Ops - a WASP Research Arena which aims to bring together Swedish industry with academic researchers.
            </Typography>
            <Typography variant='body1' paragraph>
                WARA-Ops is supported by Ericsson Research alongside the Wallenberg AI, Autonomous Systems and Software Program (WASP), funded by the Knut and Alice Wallenberg Foundation.
            </Typography>
            <Typography variant='body1' paragraph>
                The portal is hosted on infrastructure provided by the Ericsson Research Data Center (ERDC). The ERDC comprises of approximately 1000 servers of different structures
                and 50+ high-end GPUs, running at our facility in Lund, Sweden.
            </Typography>
            <Typography variant='h5' paragraph>
                Quick guide
            </Typography>
            <Typography variant='body1'>
                Data in the portal is organized into datasets, each representing a collection of operational data of a particular type from one organization.
                Viewing and accessing the data within a dataset is restricted by default.

                Each dataset has an owner who holds the ultimate authority and responsibility over its content.

                The owner can give other users access to the dataset by assigning them access roles. There are currently 2 roles to give;
            </Typography>
            <ul>
                <li>
                    <Typography variant='body1'>
                        <b>Admin:</b> Can do what the owner can, but not add or remove other admins.
                    </Typography>
                </li>
                <li>
                    <Typography variant='body1'>
                        <b>User:</b> Can view and access the files within the dataset.
                    </Typography>
                </li>
            </ul>
            <Typography variant='body1' paragraph>
                Metadata from all datasets available in WARA-Ops can be browsed under the <b>Catalog</b> tab. By clicking a row, more information will be displayed.
                Here access can also be requested for datasets where you lack any access role. Requesting access will notify the owner and admins of the said
                dataset that then can approve or reject it.
            </Typography>
            <Typography variant='body1' paragraph>
                A closer look at your selected dataset can be seen under the <b>Overview</b> tab. For owners and admins this tab also holds options for administrating the dataset.
            </Typography>
            <Typography variant='body1' paragraph>
                The data in a dataset is stored in files, which can be browsed under the <b>Files</b> tab. To access a file, go to the <b>Jupyterhub</b> tab and
                follow the instructions.
            </Typography>
        </div>
    );
}
