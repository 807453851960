import React from 'react';
import ReactDOM from 'react-dom/client';

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import axios from 'axios';
import { Provider } from 'react-redux';
import { store, persistor } from './store';
import { PersistGate } from 'redux-persist/integration/react';

import { checkLoginStatusAndRouteAsNeeded } from './lib/stateTasks';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

axios.defaults.withCredentials = true;

const siteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY || '';

const root = ReactDOM.createRoot(document.getElementById('root'));
const x = <React.StrictMode>
    <Provider store={store}>
        <PersistGate
            loading={null}
            onBeforeLift={async () => { await checkLoginStatusAndRouteAsNeeded(); } }
            persistor={persistor}>
            <GoogleReCaptchaProvider
                reCaptchaKey={siteKey}
                language="en"
                useRecaptchaNet={false}
                useEnterprise={false}
                scriptProps={{
                    async: false, // optional, default to false,
                    defer: false, // optional, default to false
                    appendTo: 'body', // optional, default to "head", can be "head" or "body",
                    nonce: undefined // optional, default undefined
              }}
            >
                <App />
            </GoogleReCaptchaProvider>
        </PersistGate >
    </Provider>
</React.StrictMode>;

root.render(x);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
